const site = {
  header: {
    menu: {
      title: "menu",
      shape: "../../assets/images/menu/menu.svg",
      navigation: [
        {
          title: "Stories",
          slug: "stories",
          shape: "../../assets/images/menu/stories.svg",
        },
        {
          title: "Background",
          slug: "background",
          shape: "../../assets/images/menu/background.svg",
        },
        {
          title: "Quit",
          slug: "quit",
          shape: "../../assets/images/menu/quit.svg",
        },
        {
          title: "Act",
          slug: "act",
          shape: "../../assets/images/menu/act.svg",
        },
      ],
    },
    url: "855-how-to-quit.org",
  },

  footer: {
    rows: [
      {
        headings: [
          {
            title: "call",
            description: "855-469-86-7848",
            style: "w-20 mw-100",
          },
        ],
      },
      {
        headings: [
          {
            title: "info",
            links: [
              { title: "About", link: "act", external: false },
              { title: "FAQ", link: "faq", external: false },
            ],
            style: "w-20 mw-50",
          },
          {
            title: "Browse As",
            links: [
              {
                title: "Privacy and Terms & Conditions",
                link: "privacy",
                external: false,
              },
              { title: "Imprint", link: "imprint", external: false },
              {
                title: "Press Kit",
                link: "https://www.dropbox.com/scl/fo/693luotk7haoth3gltkmi/h?rlkey=yimicl80lktla1rslurdx61ma&dl=0",
                external: true,
              },
              // { title: "Legal", link: "legal", external: false },
            ],
            style: "w-20 mw-50",
          },
        ],
      },
      {
        headings: [
          {
            title: "disclaimer",
            description:
              "The information on this website is for informational purposes only and is not intended to be a substitute for, or to be relied upon as, medical advice, diagnosis, or treatment. Always seek the advice of a physician or other qualified health-care provider with questions regarding a medical condition.",
            style: "w-40 mw-100",
          },
        ],
      },
    ],
  },

  landing: {
    meta: {
      slug: "",
      title: "855-How-To-Quit",
      description:
        "The helpline that turns the object of addiction into a way out.",
    },
    title:
      "855-how-to-quit-&nbsp;(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)",
    mobileTitle:
      "855<br/>How-To</br>Quit<br />(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)",
    subTitle:
      "A helpline that uses the imprint codes on opioid pills as phone extensions, to connect those who struggle with addiction with those who managed to quit. Turning the object of addiction into a way out.",

    news: {
      title: "News",
      titles: ["Date", "Title", "Description"],
      items: [
        {
          date: "11/28/2024",
          title: "Media report",
          description:
            "Recently, one of the 855-HOW-TO-QUIT volunteers wrote an <a href='https://www.adweek.com/creativity/my-journey-through-recovery-took-me-from-jail-to-cannes/' target='_blank'>editorial</a> about his participation in the helpline for the distinguished communications magazine ADWEEK. Thank you, Kyle for your courageous involvement.",
        },
        {
          date: "12/09/2024",
          title: "New stories",
          description:
            "Additional funding has enabled the addition of <a href='https://855-how-to-quit.org/stories' target='_blank'>new stories</a> from people who managed to quit opioids. By sharing these real-life experiences, we strive to create a supportive community that fosters recovery and resilience.",
        },
        {
          date: "02/01/2025",
          title: "Recognition",
          description:
            "Earlier this month the 855-HOW-TO-QUIT-(OPIOIDS) initiative was shortlisted as one of the best innovations of the year by <a href='https://www.sxsw.com/' target='_blank'>SXSW</a>. Special thanks to everyone involved, especially our volunteers and their inspiring stories.",
        },
      ],
    },

    listTitle: ["Listen to", "HOW THEY QUIT"],
  },

  stories: {
    title:
      "Their<br />St<img class='title-icon' src='../../assets/images/stories/stories-title-icon.svg' />ries",
    meta: {
      slug: "stories",
      title: "People Stories",
      description:
        "Listen to the stories of people who have recovered from their substance use disorder.",
    },
    rows: [
      { headings: [{ col: 0, title: "stories", description: "30" }] },

      {
        headings: [
          {
            col: 1,
            title: "description",
            description:
              "A helpline that uses the imprint codes on opioid pills as phone extensions, to connect those who struggle with addiction with those who managed to quit. Turning the object of addiction into a way out.",
          },
        ],
      },
      {
        headings: [
          {
            col: 2,
            title: "sort by",
            options: ["Name", "Popularity", "Strength"],
          },
          { col: 3, title: "browse as", options: ["List", "Grid"] },
        ],
      },
    ],

    listHeadings: ["opioid", "story of", "generic name", "details"],

    blocks: [
      {
        title: "How It Affects You",
        text: "Opioids work by triggering the release of dopamine in your brain, which is your brain's feel-good neurotransmitter. These muffle your perception of pain and for some people cause feelings of euphoria.<br /><br />The more you take, the more tolerance you build, the more you need to get that same feeling. With this tolerance also comes dependence, and your brain and body will crave opioids. This also means that when you stop taking opioids, you’ll experience withdrawal symptoms as your brain and body have to relearn how to function without them.",
      },
      {
        title: "In Case of Overdose",
        text: "When someone overdoses on opioids, their blood oxygen levels decrease and eventually vital organs like the brain and heart stop. It’s essential to act fast. If you do not have Naloxone available and the person is unresponsive, call 911 immediately.<br /><br />If you do have Naloxone available you’ll find SAMSHA’s guide below for first responders, but it is still highly recommended to call 911 and/or consult a medical professional as well.",
        linkText:
          "Substance Abuse and Mental Health Services Administration (SAMHSA) Opioid Overdose Prevention Toolkit",
        link: "https://store.samhsa.gov/sites/default/files/d7/priv/five-essential-steps-for-first-responders.pdf",
      },
    ],
    infoHeadings: {
      genericName: "generic name",
      strengths: "strength(s)",
      availability: "availability",
      csaSchedule: "CSA Schedule",
      potentialForAbuse: "potential for abuse",
      approvalHistory: "Approval history",
    },
    overview: "overview",
    end: "the end",
    warning: "In case of medical emergency call 911.",
    quitButton: "FIND OUT HOW TO QUIT",
    storyTitles: ["how", "quit"],
    stories: [
      {
        name: "Oxycodone",
        genericName: "Oxycodone<br />Hydrochloride",
        company: "Mallinckrodt<br />Pharmaceuticals",
        slug: "30",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/30.png",
        model: {
          file: "../../assets/models/33_30.glb",
          scale: 8,
        },
        phone: "855–469–86–7848–(30)",
        strength: "30MG",
        strengthValue: 30,
        strengths: "5, 15, 30mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "blue",
        popularity: 160,
        colors: { primary: "#CDD8E4", secondary: "#383F47" },
        person: { name: "Emily" },
        audio: "../../assets/sound/HTQ_01_Emily.mp3",
        script: [
          { text: "30.", title: "P.01", style: "pill-title" },
          {
            text: "Thirty, thirty, thirty. Well, definitely there's been more than 30 times where I thought – ‘Just this one time’.",
          },
          { style: "callout", text: "I’m Emily, and this is how I quit Oxy." },
          {
            text: "It all started when I was – 17? Growing up, I was in dance classes, and I picked up a knee injury.",
            title: "P.02",
          },
          {
            text: "So I went to the doctor and he gave me Oxy for the pain. And the problem is they work too well. There is no pain, in fact you feel better than good. And the doctor just keeps writing that prescription, he's trying to help you out – until there is no way out.",
          },
          {
            text: "When every exit seems to be closed, you need a guiding light. Mine was a recommended therapist. Because Oxys don't just numb the pain of the body – it numbs the mind too. It becomes your way of dealing with stuff, so the mind forgets how to deal with itself.",
            title: "P.03",
          },
          {
            text: "My therapist told me – 'Taper your dose'. So every week I took some milligrams less, until the withdrawal hit. (Sighs) It's not easy, but you can fight back with Ibuprofen, vitamins, water, food – you know, the wellness package. Oh and exercise. But the thing that really kept me sane was actually meditation, it taught me to separate myself from the Oxy.",
            title: "P.04",
          },
          {
            text: "Keep that in mind, know that your addiction is not you; it feels like you because you’ve spent so much intimate time together, but you can choose to spend some time apart – and you're not alone in this.",
            title: "P.05",
          },

          {
            text: "It won't be easy, trust me, but it's gonna be worth it.",
            style: "callout",
          },
        ],
      },
      {
        name: "Morphine",
        genericName: "Morphine Sulfate<br />Extended-Release",
        company: "Mallinckrodt<br />Pharmaceuticals",
        slug: "m",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/m.png",
        model: {
          file: "../../assets/models/30_M.glb",
          scale: 6.5,
        },
        phone: "855–469–86–7848–(6)",
        strength: "30MG",
        strengthValue: 30,
        strengths: "10, 20, 30, 45, 60, 75, 80, 90mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "purple",
        popularity: 220,
        colors: { primary: "#E4CDDF", secondary: "#493645" },
        person: { name: "Jason" },
        audio: "../../assets/sound/HTQ_02_Jason.mp3",
        script: [
          { text: "M.", title: "P.01", style: "pill-title" },
          {
            text: "Mmmm, I swear it's like candy, you know you can't eat just one, can you? Yeah. But you know I – I finally did it.",
          },
          { style: "callout", text: "I'm Jason and I quit Morphine." },

          {
            text: "So I tried it for the first time, when I was like, I don't know 12 or 13?",
            title: "P.02",
          },
          {
            text: "My dad had terminal cancer and I grew up with a single dad. And he had cancer. He was prescribed Ms, Oxys, Hydros... every two weeks. So, there were always pills lying around, you know?",
          },
          {
            text: "And they worked for him, of course they did, because he was in pain. But I was just you know... young and stupid. So I tried 'em and I started liking 'em.",
          },
          {
            text: "And then – well then my father died. So, yeah.",
          },
          {
            text: "I honestly just forgot how to live a normal life, you know, sober. I uh – Like, I, I didn't know how to deal with life.",
            title: "P.03",
          },

          {
            text: "I just realized that if I wanted to quit there had to be a bigger part of me that wanted to stay clean, than a bigger part of me that wanted to feel high – Cuz just, there's always gonna be a part of me that wants to get high. Like, for the rest of my life, you know? And I know that. So I had to find, you know, like... my natural high.",
          },

          {
            text: "And to me that was group therapies at a harm reduction clinic, where I met a lot of cool people, right? We did yoga, we ate pancakes, drank coffee, but we were vulnerable with each other. We hung out, you know. I had my peeps, right. I was just, finally not alone.",
          },
          {
            text: "It made me see that I didn‘t want my legacy to be – like look I‘m afraid of getting high and dying. Like from an overdose, right? And I don‘t want that to be my legacy.",
            title: "P.04",
          },
          {
            text: "I'm finally doing good.  And look I feel you.",
          },
          {
            text: "We're all in this. So let's help each other out.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Hydrocodone Bitartrate",
        genericName: "Acetaminophen and<br />Hydrocodone Bitartrate",
        company: "Mallinckrodt<br />Pharmaceuticals",
        slug: "m365",
        shape: "../../assets/images/pills/pill-shape-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/m365.png",
        model: {
          file: "../../assets/models/31_M365.glb",
          scale: 8,
        },
        phone: "855–469–86–7848–(6365)",
        strength: "30MG",
        strengthValue: 30,
        strengths: "2.5/325, 5/325, 7.5/325, 10mg/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1982",
        color: "white",
        popularity: 20,
        colors: { primary: "#DDDED3", secondary: "#444639" },
        person: { name: "David" },
        audio: "../../assets/sound/HTQ_03_David.mp3",
        script: [
          { text: "M365.", title: "P.01", style: "pill-title" },
          {
            text: "Like 365 days in a year that passes by in a flash, blurs out and spins around you, and then it spits you out.",
          },
          { style: "callout", text: "Hey, I'm David and I quit Hydros." },

          {
            text: "So, growing up I was a good kid, man. I had good grades, I was athletic, competitive. But my family was – I mean my dad was addicted to heroin, my mom to pills, like Xanax and all that, and – you know they fought all the time, so I didn't like to be home much, and I felt lonely, you know?",
            title: "P.02",
          },
          {
            text: "So when I was 18 or so, a friend offered me to sniff a painkiller and I just thought, you know 'sure, why not?'",
          },

          {
            text: "And I didn't have the money to buy the good stuff, so I started doing shady stuff and yeah, got arrested and landed in jail. For a year.",
            title: "P.03",
          },

          {
            text: "I got out of the joint, wanting to turn my life around, so I went to New York to get treatment but I didn't have insurance, you know. So I looked and looked, until I found a place where they gave me a 90-day program scholarship. I moved to Florida to a sober house, for like 18 months, and huh I met my fiance there. Yeah – she isn't an addict or anything, she was a volunteer and she, she just, you know, she accepted me for who I was, which – well was a first for me, and I haven't looked back since.",
          },

          {
            text: "So you gotta ask yourself, what do you really want for your life, and when was the last time you were truly, happy? And if you don't have the answer but you're looking for a change, or look even if you're not looking for a change, ",
            title: "P.04",
          },
          {
            text: "talk to someone, cuz who knows?",
            style: "callout",
          },
        ],
      },
      {
        name: "Hydromorphone Hydrocodone",
        genericName: "Hydromorphone<br />Hydrochloride",
        company: "Rhodes<br /> Pharmaceuticals",
        slug: "p",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/p.png",
        model: {
          file: "../../assets/models/29_P.glb",
        },
        phone: "855–469–86–7848–(7)",
        strength: "2MG",
        strengthValue: 2,
        strengths: "2, 4, 8mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "orange",
        popularity: 300,
        colors: { primary: "#E4D9CD", secondary: "#494036" },
        person: { name: "Pauline" },
        audio: "../../assets/sound/HTQ_06_Pauline.mp3",
        script: [
          { text: "P.", title: "P.01", style: "pill-title" },
          {
            text: "I've never liked that my name starts with the same letter as the pill!",
          },
          {
            style: "callout",
            text: "I'm Pauline and this is how I quit uhh – how did they call it? Ah, Dillies.",
          },

          {
            text: "In case you haven't noticed I'm 68 years old. I was never a big alcohol fan, never smoked, didn't party much, nothing, none of that.",
            title: "P.02",
          },
          {
            text: "But with age comes pain, and sometimes that pain becomes unbearable.",
          },
          {
            text: "So, my doctor recommended these new pills and I just didn't think twice about it, why would I? Nobody mentioned that I could get... addicted.",
          },

          {
            text: "It never, ever, crossed my mind that something like this could just sneak up on me. I was in denial. In fact, it angered me when somebody mentioned I might have an issue, but I had to come to terms with it. Yeah – I talked to a very nice nurse, she reminded me of my niece, so I think that helped, and she walked me through the process in a very tender manner. She said that for my stage, the best shot was via medication and therapy, straight and simple. So, I did exactly that, yeah.",
            title: "P.03",
          },

          {
            text: "Well, of course it wasn't easy, it was painful, but I'm grateful to her – and to God for the strength – because I learned to deal with the pain, without opioids.",
          },

          {
            text: "My main takeaway is this: if you're addicted, embrace it, don't deny it. Talk about it, because you will meet your nurse, you will attract the strength you need. And keep your hope, hold on to it. Believe in yourself, because we believe in you.",
            title: "P.04",
          },
          {
            text: "If you're addicted, embrace it, don't deny it.",
            style: "callout",
          },
        ],
      },
      {
        name: "Morphine",
        genericName: "Morphine",
        company: "Inspirion Delivery<br />Technologies, LLC.",
        slug: "idtm60",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/idtm60.png",
        model: {
          file: "../../assets/models/20_IDTM60.glb",
          scale: 6,
        },
        phone: "855–469–86–7848–(438660)",
        strength: "60MG",
        strengthValue: 60,
        strengths: "5, 30, 60, 100mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "orange",
        popularity: 230,
        colors: { primary: "#E4D3CD", secondary: "#493B36" },
        person: { name: "Benji" },
        audio: "../../assets/sound/HTQ_14_Benji.mp3",
        script: [
          { text: "IDTM60.", title: "P.01", style: "pill-title" },
          {
            text: "Huh. If it ended in 69 it would be my age, today... Wow.",
          },
          {
            style: "callout",
            text: "This is Benji speaking and this is how I quit Morphine.",
          },

          {
            text: "Once you become addicted to something, whatever it may be, you lose. You lose yourself, you know. I don't even remember how it started, and to be honest after all this time, it doesn't even matter really.",
            title: "P.02",
          },
          {
            text: "I just remember now that I was surrounded by violence and bad decisions, decisions like, like when you hear someone OD'd from a certain pill, you wanna go get that. I mean, that was me.",
          },

          {
            text: "Well three things happen to you if you don't quit and, and I learned this the hard way. It's either prison, a nut ward, or death. One of them I was headed towards. But it took 30 years to realize this, man, this is not my kind of living.",
            title: "P.03",
          },

          {
            text: "And I've been on Suboxone for a year now, I don't like its kick but it keeps me off the pills and I'm hoping that soon I can quit that too. I found a job at a store, it's 9-hours a day, which, I mean honestly I could've been doing 20 years ago, but look it keeps me off the streets, and my mind is occuppied in keeping this place clean, rather than keeping myself high.",
          },

          {
            text: "Now today, today I believe in myself, I have all the confidence in the world and myself. And it's true I hurt, I hurt a lot of people, burnt bridges, but I’ve been working on it, making amends, and some doors are reopening now. This is what I wish for you, that you see it's never too late to fight this battle. ",
            title: "P.04",
          },
          {
            text: "But hey, this phone call is already a start, so keep at it.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxycodone",
        genericName: "Oxycodone",
        company: "Purdue<br />Pharma LP",
        slug: "op",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/op.png",
        model: {
          file: "../../assets/models/18_OP.glb",
          scale: 4.8,
        },
        phone: "855–469–86–7848–(67)",
        strength: "60MG",
        strengthValue: 60,
        strengths: "10, 15, 20, 30, 40, 60, 80mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "red",
        popularity: 190,
        colors: { primary: "#E4D0CD", secondary: "#4A3937" },
        person: { name: "Brittany" },
        audio: "../../assets/sound/HTQ_15_Britanny.mp3",
        script: [
          { text: "OP.", title: "P.01", style: "pill-title" },
          {
            text: "Oh Pee. That's how they call the Original Poster in meme sites, (chuckles) which is another addiction of mine.",
          },
          {
            style: "callout",
            text: " Hey I'm Brittany and this is how I quit Oxycodone.",
          },

          {
            text: "I was not only addicted to memes, my first pill was a 5mg Oxy, post-op, after surgery. That graduated to a 10mg Percocet, to 30mg Oxy, to 80mg Oxycontin, to adderrall, valium, diazepam, the list goes on and on.",
            title: "P.02",
          },
          {
            text: "Ummm, that's of most us, I guess. Uh like, I remember I overdosed once in high school, and the nurses at the hospital were just so rude to me, which just made me – uh suspicious, and after that it went downhill for like a long while.",
          },

          {
            text: "I was living in the streets for like a couple of years I think, saw a lot of death around me, got used to doing the unthinkable for just any dope I could grab, until one day, I don't know if it was like God sent or what it was, but this undercover cop posing as a dealer started hanging out with me. He got ahold of my family, let them know where I was, they came looking for me and they, they supported me.",
            title: "P.03",
          },

          {
            text: "I got in contact with Savage Sisters, and they're all about harm reduction, and they gave me housing. So it was through them I found a new way of life, of understanding and like, just knowing that there is help out there when you need it.",
          },

          {
            text: "My only purpose by telling you this is letting you know one of the options out there. And like, if you don't want to quit, that's ok, but at least learn to do it right, there's a better way.",
            title: "P.04",
          },
          {
            text: "There's a whole community of people that just wants to help us, so if and when you feel like it, we'll be waiting.",
            style: "callout",
          },
        ],
      },
      {
        name: "Morphine",
        genericName: "Morphine",
        company: "Inspirion Delivery<br />Technologies, LLC.",
        slug: "pf",
        shape: "../../assets/images/pills/pill-shape-wide-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/pf.png",
        model: {
          file: "../../assets/models/19_PF.glb",
          scale: 6.8,
        },
        phone: "855–469–86–7848–(73)",
        strength: "200MG",
        strengthValue: 200,
        strengths: "15, 30, 60, 100, 200mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "blue",
        popularity: 250,
        colors: { primary: "#CDE4DE", secondary: "#364944" },
        person: { name: "Jared" },
        audio: "../../assets/sound/HTQ_18_Jared.mp3",
        script: [
          { text: "PF.", title: "P.01", style: "pill-title" },
          {
            text: "Pfff, as in I didn't give a fuck, I didn't care that I was addicted to this.",
          },
          {
            style: "callout",
            text: "But, look, listen, let me tell you how I quit Morphine.",
          },

          {
            text: "My name is Jared, my story started in high school, freshman, or, or sophmore year, yeah. I was in a fight. My sister was uh, I don't know making out with some roid rager, so I swung on him and my buddy grabbed me. I gotta be honest I don't know what happened but my ankle ended up getting snapped on the stairs and well, the pain was something let me tell you.",
            title: "P.02",
          },
          {
            text: "They gave me morphine at the hospital and well that was it, after that I couldn't function without it.",
          },

          {
            text: "And I tried to stay clean but I relapsed and got myself evicted. And then momma got sick so, my goal became to stay alive, like, I don't want to die before my ma'. I mean she shouldn't have to bury her son, right?",
            title: "P.03",
          },

          {
            text: "So I went back to rehab, you know, got my meds and my Suboxone, my buddies and – you know myself, I got, I got myself back, my emotions and like my mind, you know. And look the itch, I'll be honest the itch it still comes, but now I know how to fight back.",
          },

          {
            text: "I spent almost 7 or 8 years completely disconnected from my family, thinking that our relationship was broken beyond repair. But ever since I got my life back on track I reached out to them, thinking hell I had nothing to lose, and I'll tell you, it was the best thing, to just feel their warmth again, you know.",
            title: "P.04",
          },
          {
            text: "Whatever you may have lost, I promise there's still a way to win it back.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxycodone Hydrochloride",
        genericName: "Oxycodone<br />Hydrochloride",
        company: "Xanodyne<br />Pharmaceuticals, Inc.",
        slug: "0145",
        shape: "../../assets/images/pills/pill-shape-wide-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/0145.png",
        model: {
          file: "../../assets/models/16_145.glb",
          scale: 6,
        },
        phone: "855–469–86–7848–(145)",
        strength: "5MG",
        strengthValue: 5,
        strengths: "5, 10, 15, 20, 30mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "white",
        popularity: 90,
        colors: { primary: "#DFDCD3", secondary: "#46433A" },
        person: { name: "Kara" },
        audio: "../../assets/sound/HTQ_19_Kara.mp3",
        script: [
          { text: "145.", title: "P.01", style: "pill-title" },
          {
            text: "Yeah I must have seen this code way more than 145 times.",
          },
          {
            style: "callout",
            text: "I'm Kara and this is how I quit Oxy.",
          },

          {
            text: "I tried Oxy for the first time when I was, maybe 16 years old, it was through a friend who recently had a surgery. Soon enough it dawned on me that I didn't have an off button. I didn't know that I didn't have an off button, but I didn't have an off button – and I got involved with more drugs and even drug deals, to support my habit but also just to survive.",
            title: "P.02",
          },
          {
            text: "I uh, started selling pills when I was 24 and after a few months I got chased down a highway, like O.J. Simpson, like as if I was like the ultimate criminal.",
          },

          {
            text: "I didn't know this language then, but I know this language now, and the language is emotionally sober. So, after 8 and a half years of being clean and sober, but not emotionally sober, so you know not really working a plan, I was just charging through it and doing it on my own, but quite frankly I wasn't ready enough.",
            title: "P.03",
          },

          {
            text: "So then after jail I got sober, but it was not until I – actually it was not until I started following a program at a clinic that I started to just be, not sober, but you know, I started to develop mental tools, physical tools, medical ones, the whole combo.",
          },

          {
            text: "I have to do things on a daily basis that take care of me, because that's what works for me. You know my motivation used to be what are people going to think of me, how can I impress them, or prove to them that I'm good, but no, ",
            title: "P.04",
          },
          {
            text: "now I do this for myself, not the rest of the world. And that's where the good stuff happens.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxymorphone Hydrochloride Extended-Release",
        genericName: "Oxymorphone Hydrochloride<br />Extended-Release",
        company: "Amneal<br />Pharmaceuticals LLC",
        slug: "g73",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/g73.png",
        model: {
          file: "../../assets/models/14_G73.glb",
          scale: 8.8,
        },
        phone: "855–469–86–7848–(473)",
        strength: "20MG",
        strengthValue: 20,
        strengths: "5, 7.5, 10, 15, 20, 30, 40mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1959",
        color: "green",
        popularity: 210,
        colors: { primary: "#CDE4D4", secondary: "#36493C" },
        person: { name: "Joy" },
        audio: "../../assets/sound/HTQ_20_Joy.mp3",
        script: [
          { text: "G73.", title: "P.01", style: "pill-title" },
          {
            text: "I figure that's the name of a sports car model, one where if you go fast enough, it makes you feel real high.",
          },
          {
            style: "callout",
            text: "I'm Joy and this is how I quit Oxy.",
          },

          {
            text: "The first time I tried Oxy not only did I like it, I loved it. It just gave me a sense of peace that I didn't have to worry about stuff anymore. I was a correctional officer and that's where my disease started. It's a stressful place and physically demanding, so other officers recommended I go and visit a doctor.",
            title: "P.02",
          },
          {
            text: "He didn't ask me much, but what he did do, was give me was a prescription for 2 months. And it did make me feel better, so even when I wasn't in any pain my doctor was still writing prescriptions for me, because I told him I needed it to function properly.",
          },

          {
            text: "I didn't want anybody to look down on me, I didn't want to disgrace my family and I could already see the stress I put them under. I saw me too and it broke my heart. It was the most traumatic thing that I've ever seen in my life and I just, I just cried out to God, please, so anyways, my cry led me to a phone call. ",
            title: "P.03",
          },

          {
            text: "I started speaking with Hopkins Bayview recovery center, and they walked me through the whole process. Simple, simple as asking me how ready I was for a change from 1 to 10. They also made me acknowledge the burden I was carrying with my shame, they gave me medicine and honestly just something to look forward to. Familiar faces that know all your demons and yet, still make you smile at every single day.",
          },

          {
            text: "The sky is the limit, the sky and beyond. I feel like I'm finally free to choose, because I didn't have a choice when I was under the influence, you know. Now, I'm free to love, because I didn't know how to before. And becase I'm free to talk to someone, like you, to tell you it's ok.",
            title: "P.04",
          },
          {
            text: "There's plenty of us who are here for you, just waiting for your call.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Hydrocodone Bitartrate",
        genericName: "Acetaminophen and<br />Hydrocodone Bitartrate",
        company: "Qualitest<br />Pharmaceuticals",
        slug: "3601",
        shape: "../../assets/images/pills/pill-shape-ellipse.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/3601.png",
        model: {
          file: "../../assets/models/25_3601.glb",
          scale: 5.9,
        },
        phone: "855–469–86–7848–(3601)",
        strength: "10MG",
        strengthValue: 10,
        strengths: "2.5/325, 5/325, 7.5/325, 10mg/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1982",
        color: "green",
        popularity: 70,
        colors: { primary: "#E4E2CD", secondary: "#494736" },
        person: { name: "Adam" },
        audio: "../../assets/sound/HTQ_21_Adam.mp3",
        script: [
          { text: "3601.", title: "P.01", style: "pill-title" },
          {
            text: "3-6-0-1. It's like a pin code for an ATM, but instead of cash, you get pills... and of course a lifetime of debt.",
          },
          {
            style: "callout",
            text: "So, my name is Adam and yeah I guess this is how I quit Hydros.",
          },

          {
            text: "I believe I was an addict, even before I picked up a drug. I mean I tried weed, I tried alcohol, and all that but, I never really, I never really faced any consequences. I was a, I was a smart kid, you know school came easy, and, so I ended up getting a pharmacy tech certification.",
            title: "P.02",
          },
          {
            text: "I got licensed, and you know, I'm working as a pharmacy tech and it's about this time I get introduced to Hydrocodone. And, you know, being on the field I know about the dangers of opioid medication, and, I don't know, just one day I tried one, I wasn't really thinking about the consequences.",
          },

          {
            text: "And for so many years I was high, like so high, huh, like I get – I was so high I just got used to it right? I got used to being always high and always thinking about that next fix. But, after a while what happens, was it started actually being less high, you know, so then you're just – you're taking some, just to get past the day.",
            title: "P.03",
          },

          {
            text: "It took me almost a decade to realize I needed to – I needed help, I needed to get help, you know. And I tried detox, I tried outpatient, and they might have worked if I had I been willing to do it, but I wasn't really willing at the time. Later I did a 7-day detox at the Valley Hospital, and I really really wanted inpatient. Cuz' I was sick and tired of being sick and tired. After detox I did the 30-day program at Cavalry Healing, and I did everything they asked of me man, I was in it, you know, because I needed to get through it, and huh, thankfully I gotta be honest thankfully I did.",
          },

          {
            text: "Now it took me a while to see that I was addicted to – more. You know, I was addicted to more, and I think that's the first thing, realizing what it is about you that keeps you going back to the pill. 'Cuz once you nail that in, you can train the body to live without it, once the mind is in check, you know.",
            title: "P.04",
          },
          {
            text: "So if you're thinking about quitting, please, please, please keep trying.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxycodone<br />Hydrochloride",
        genericName: "Oxycodone<br />Hydrochloride",
        company: "KVK Tech<br />Inc.",
        slug: "k9",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/k9.png",
        model: {
          file: "../../assets/models/32_K9.glb",
        },
        phone: "855–469–86–7848–(59)",
        strength: "30MG",
        strengthValue: 30,
        strengths: "5, 10, 15, 20, 30mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "blue",
        popularity: 170,
        colors: { primary: "#CDDAE4", secondary: "#384147" },
        person: { name: "Jack" },
        audio: "../../assets/sound/59-(K9)-Jack-Oxycodone-V1.mp3",
        script: [
          { text: "K9.", title: "P.01", style: "pill-title" },
          {
            text: "It kinda sounds like 'canine', you know like a dog, no?",
          },
          {
            style: "callout",
            text: "Hey, I'm Jack and I want to share with you how I quit Oxy.",
          },

          {
            text: "I was, or I don't know am, whatever, a trustfund kid, but it left me wanting more. Like relationship-wise, like out of people, because I was raised by au pairs mainly, and they only spoke Spanish, and I didn't speak Spanish. So from this loneliness I became a people pleaser, a &quot;yes&quot; kid. That led to parties, booze, girls, weed, drugs, you know. And then came the Kay-nine, and it opened the door to meth. After that I was just really running the rails, just strung out. ",
            title: "P.02",
          },

          {
            text: "I became self-destructive, like whenever something good happened to me I was worried of how I would fuck it all up. I kind of reveled in the chaos and misery.",
            title: "P.03",
          },

          {
            text: "I tried quitting, believe me. Rehab: nada. Medicine: nada. Therapy: nada. Almost losing a leg: nada.",
          },
          {
            text: "And then I went to see a counselor. She started saying these things, like how when the victim, when you play the victim you just forfeit that power and you just never get it back, and it caught me at the right time. Like, it got me thinking – there's no winners that are blaming everybody else for their success, right?",
          },

          {
            text: "Like if I had to give you an advice, I would just say, just do the next right thing and you'll be fine. Whatever it is, just do the next right thing. Not a big one, the little one. It's not always easy, but you'll know right from wrong.",
            title: "P.04",
          },
          {
            text: "And you're a good person and you have talents that the world needs to see, believe it, or at least try.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Oxycodone",
        genericName: "Acetaminophen and<br />Oxycodone",
        company: "Endo<br /> Pharmaceuticals Inc.",
        slug: "percocet",
        shape: "../../assets/images/pills/pill-shape-wide-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/percocet.png",
        model: {
          file: "../../assets/models/28_Percocet.glb",
          scale: 7.2,
        },
        phone: "855–469–86–7848–(73726238)",
        strength: "10MG",
        strengthValue: 10,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1976",
        color: "yellow",
        popularity: 130,
        colors: { primary: "#E4E3CD", secondary: "#494836" },
        person: { name: "Kyle" },
        audio: "../../assets/sound/73726238-(PERCOCET)-Kyle-Percocet.mp3",
        script: [
          { text: "Percocet.", title: "P.01", style: "pill-title" },
          {
            text: "Perc 10’s, yellows, bananas – when I saw that yellow pill with the imprint 10/325 I got high. The pills weren't even in my system yet and my body and brain were already firing on all cylinders. The high before the high. I knew in a few moments that pill would be on its way to my stomach and the pain would disappear. When I saw 10/325 I usually wondered if I had enough money for 10 of them. My addiction was progressing. What one pill used to do now took 4 and eventually 20.",
          },
          {
            style: "callout",
            text: "Hi, I’m Kyle and this is how I quit Percocet and got my life back.",
          },

          {
            text: "I recognized early on that I had something different in my brain than others around me. I had that need for more. Some may call it the addiction gene. For me, it meant I pushed things to the limit and then shattered the limit. Any substances that were offered to me I was willing to give it a shot once, once always turned into more.",
            title: "P.02",
          },
          {
            text: "Although I had a sinking suspicion that I had a problem with substances it didn’t click in until I was introduced to opioids. I got hurt in college football and was prescribed opioids. Almost from the start, I was abusing my prescription. Taking more than I was supposed to, taking it with alcohol, taking it with other substances. And by the time my doctor caught on I had a daily habit that would result in withdrawal symptoms if I didn't take some pills.",
          },
          {
            text: "I realized around this time that I was hooked on opioids and had a bad addiction. I wanted to stop taking them but I didn’t know how or if I could stop. My addiction progressed at a rapid rate and ended with me losing my freedom and landing in prison.",
          },

          {
            text: "My second time going back to prison was a wake-up call. I was finally ‘tired of being sick and tired’. I was tired of this little powder, this little pill controlling my life. All my actions daily were not Kyle, they were for that little pill.",
            title: "P.03",
          },

          {
            text: "I decided to try a different way of life, I stopped trying to do it on my own in secret. I opened up and asked for help. Upon my release, I got heavily involved in the recovery community in my area. This involved outpatient rehab, MAT (medication-assisted treatment), and 12-step programs. Specifically AA. Even though alcohol was not my main substance of choice, they welcomed me with open arms. 12-step programs saved my life.",
          },
          {
            text: "If you're struggling, I want to let you know ‘You don't have to do this alone’. I thought I was the only one in the world going through this and no one could understand why I used drugs and alcohol. When I could put my ego aside and ask for help everything changed in my life.",
            title: "P.04",
          },
          {
            text: "I found out there were millions of others out there who struggled with the same problem as me and found a way out.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Oxycodone Hydrochloride",
        genericName: "Acetaminophen and<br />Oxycodone Hydrochloride",
        company: "Mallinckrodt<br />Pharmaceuticals.",
        slug: "512",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/512.png",
        model: {
          file: "../../assets/models/27_512.glb",
          scale: 4.2,
        },
        phone: "855–469–86–7848–(512)",
        strength: "5MG",
        strengthValue: 5,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1976",
        color: "white",
        popularity: 100,
        colors: { primary: "#DFDAD3", secondary: "#464139" },
        person: { name: "Brock" },
        audio: "../../assets/sound/512-(512)-Brock-Oxycodone-V1.mp3",
        script: [
          { text: "512.", title: "P.01", style: "pill-title" },
          {
            text: "As a former cop I know this code, it means 'I can't make it', like no backup, no support.",
          },
          {
            style: "callout",
            text: "Hey there, I'm Brock and this is how I quit Oxy.",
          },

          {
            text: "I worked for the Mesa Police Department for uh seven years. I was working narcotics and I was run over on duty. At that point in time I was prescribed medication opioids, Oxy, and they were prescribing me two, three months at a time, so in the meantime I could go from one doctor to the next and I had six months of pills. I kind of came to a day where I was out of medication for like five or six hours, and I thought I was gonna die.",
            title: "P.02",
          },

          {
            text: "I understand why people don't want to get clean, but the reason we're in these addictions is because we see nothing else. My brother made me see something else, so he pulled me back in, before I moved on to more dangerous things.",
            title: "P.03",
          },

          {
            text: "He started the Blue Vase Recovery Center and I joined shortly after. The first six months were rough, but medication, therapy, socializing makes it manageable. I felt like I could find the strength, and thankfully I did, I found a bigger cause and that kept pushing me into the right direction.",
          },

          {
            text: "As someone who recovered, my goal is to help people replace their addiction with something bigger and better. The first step is admitting that you have an issue, to submit, and once you voice it out,",
            title: "P.04",
          },
          {
            text: "there's a spark of hope that we can all help you feed, until it opens a whole new path. And that's exciting!",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Codeine Phosphate",
        genericName: "Acetaminophen and<br />Codeine Phosphate",
        company: "Amneal<br />Pharmaceuticals.",
        slug: "ip33",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/ip33.png",
        model: {
          file: "../../assets/models/26_ip33.glb",
          scale: 4.6,
        },
        phone: "855–469–86–7848–(4733)",
        strength: "30MG",
        strengthValue: 30,
        strengths: "15/300, 30/300, 60/300mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1974",
        color: "white",
        popularity: 280,
        colors: { primary: "#DFD9D3", secondary: "#464039" },
        person: { name: "Simran" },
        audio: "../../assets/sound/4733-(IP33)-Simran-Codeine-V1.mp3",

        script: [
          { text: "IP33.", title: "P.01", style: "pill-title" },
          {
            text: "IP33, that always make me think &quot;I pee&quot;, like I have to pee, and then 33. I don't know if that makes any sense.",
          },
          {
            style: "callout",
            text: "Anyway, I'm Simran and this is how I quit Codeine.",
          },

          {
            text: "Before IP33 everything was just fine, I had a steady job, a husband, and a daughter. But soon after I had her, our marriage just changed. He – He pried on my insecurities, like how I was too chubby after the pregnancy and it made me feel, ummm, insecure. So I started going to the gym like, punishing myself, and in that process I hurt my back. I had to go through surgery and then to ease the pain, they gave me Codeine. Finally I was eating lesser, shedding weight, not snacking, And I really thought I was doing well. It was false, it was it was a band-aid, even though I thought I was healing.",
            title: "P.02",
          },

          {
            text: "But then shortly after, my husband underwent life saving heart surgery. And as a part of his recovery I booked him a meditation course about how to control your pain, mentally, and although it was for him some reason it planted the seed of change for me.",
            title: "P.03",
          },

          {
            text: "I um, I asked my doctor to help me lower my dose, so I weaned myself from my meds, slowly. And then he recommended that I go to group therapy, just kind of give in to that shame and just say out loud, 'Hi, I'm Simran, I'm an addict', And so I, I hit rock bottom.",
          },

          {
            text: "But this became this solid foundation that helped me rebuild everything.",
            title: "P.04",
          },
          {
            text: "So embrace your rock bottom, because it will only get better.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Hydrocodone Bitartrate",
        genericName: "Acetaminophen and<br />Hydrocodone Bitartrate",
        company: "Watson<br />Pharmaceuticals",
        slug: "watson",
        shape: "../../assets/images/pills/pill-shape-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/watson.png",
        model: {
          file: "../../assets/models/24_Watson.glb",
          scale: 5.4,
        },
        phone: "855–469–86–7848–(928766)",
        strength: "5MG",
        strengthValue: 5,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1982",
        color: "white",
        popularity: 30,
        colors: { primary: "#DFD9D3", secondary: "#464039" },
        person: { name: "Ava" },
        audio: "../../assets/sound/928766-(WATSON)-Ava-Hydrocodone-V1.mp3",

        script: [
          { text: "Watson.", title: "P.01", style: "pill-title" },
          {
            text: "Watson! No shit Sherlock!",
          },
          {
            style: "callout",
            text: "Hi, I'm Ava and this is how I quit Watson, AKA, Hydrocodone.",
          },

          {
            text: "I was an average girl, maybe a little crazy but nothing too crazy, you know? I mean I drank and I smoked weed, but, rarely. Then one day I was at volleyball practice and we were doing spike drills. And I think I got a litte too hyped because I was on a streak, so yeah I went up for a jump, but I slipped right before and I hit the ground, so fucking hard. Like, don't ask me how but somehow I broke my arm in two places and I tore the tendons on my left leg. And umm, at the hospital they gave me Morphine for the pain, then Fentanyl, and then I had to wait to go through the surgery, so in the meantime they prescribed me Watsons.",
            title: "P.02",
          },
          {
            text: "And I was just hooked after that, without even realizing it. And after a year I started getting these uh, these street pills, and it really kind of spiraled down. Ummm, yeah I got hooked to a bunch of other stuff after that.",
          },

          {
            text: "But then, my wake up call came around the fall of 2020. I was in an abandoned flat doing drugs with so-called friends, and uh, I mean I passed out in the bath tub. When I woke up alone, my bank card and my driver's license were gone. I then came clean to my parents and they took me to rehab. Yeah but it was a different kind of rehab. I actually tried the Psylocibin program for like 3 months – and so like magic mushrooms, and it was beautiful.",
            title: "P.03",
          },

          {
            text: "Like after every session I remember talking to the others in the program and we really like, we supported each other, there was no shame, just love. It was easier to connect because we were all together, and well, once your mind is better, it's easier to align the body.",
          },

          {
            text: "I know how hard it is to quit and whoever says it's easy, is lying. The good thing today is more people are prepared, like you can get meds, there's different programs, different companies, support, sometimes even for free.",
            title: "P.04",
          },
          {
            text: "A better life, it's out there.",
            style: "callout",
          },
        ],
      },
      {
        name: "Fentanyl",
        genericName: "Fentanyl",
        company: "Cephalon<br />Inc.",
        slug: "c",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/c.png",
        model: {
          file: "../../assets/models/23_C Fentanyl.glb",
          scale: 6,
        },
        phone: "855–469–86–7848–(2)",
        strength: "600MG",
        strengthValue: 600,
        strengths: "100, 200, 300, 400, 600, 800mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1968",
        color: "white",
        popularity: 290,
        colors: { primary: "#D3D3DF", secondary: "#3A3A46" },
        person: { name: "Brenda" },
        audio: "../../assets/sound/2-(C)-Brenda-Fentanyl-V1.mp3",

        script: [
          { text: "C.", title: "P.01", style: "pill-title" },
          {
            text: "As in C for &quot;curse&quot;. Yeah, this little pill is our cross to bear, our toxic relief.",
          },
          {
            style: "callout",
            text: "My name is Brenda and this is how I quit Fentanyl.",
          },

          {
            text: "I guess I am the product of generational trauma? My mother drank and did hard drugs, my grandfather was an alcoholic. And who knows about my father, never met him and his absence made me feel kind of hollow, inside. And that hollowness? I filled it with drugs, and then Fentanyl came along after I had a car accident. I was unconscious, woke up with a bunch of injuries and they gave me Fentanyl. I knew from day one that I was liking it a bit too much.",
            title: "P.02",
          },

          {
            text: "When I was 22 or 23, after using a bunch of drugs for like 8 years or so, I got evicted, I was fired, had overdosed, and I thought I had nothing else to lose, until I ended in jail. Jail gave me no choice, it kinda forced me into recovery. Oh and that happened right after finding out I was pregnant, so – yeah. I decided I needed help and I got into a residential program. I completed the program and gave birth to my daughter. After that I went to a Transitional Living Program.",
            title: "P.03",
          },

          {
            text: "Since Fentanyl is so strong they hooked me up with medication and therapy. I went from 196 mg of methadone a day to 14 mg a day. I have been tapering off every week and I feel I am almost there. I'm trying not to rush into it.",
          },

          {
            text: "If you have the impulse to quit, please hold on to it. Even if you fall, we'll help you get back up.",
            title: "P.04",
          },
          {
            text: "Just don't lose hope in yourself, because we won't. So come on.",
            style: "callout",
          },
        ],
      },
      {
        name: "Hydromorphone",
        genericName: "Hydromorphone",
        company: "Mallinckrodt<br />Pharmaceuticals.",
        slug: "exh12",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/exh12.png",
        model: {
          file: "../../assets/models/22_EXH12.glb",
          scale: 6,
        },
        phone: "855–469–86–7848–(39412)",
        strength: "12MG",
        strengthValue: 12,
        strengths: "8, 12, 16, 32mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "orange",
        popularity: 320,
        colors: { primary: "#E4DECD", secondary: "#494436" },
        person: { name: "Juan" },
        audio: "../../assets/sound/39412-(EXH12)-Juan-Hydromorphone-V1.mp3",
        script: [
          { text: "EXH12.", title: "P.01", style: "pill-title" },
          {
            text: "You know what's crazy, one day I was sitting on my front step and I saw EXH12 on the license plate of a car, I swear, it felt like it was just chasing me. But anyway,",
          },
          {
            style: "callout",
            text: "I'm Juan and this is how I quit Hydromorphone.",
          },

          {
            text: "You know growing up I was in school and all I heard about was the families and their white picket fence and everybody's hanging around, hugging each other and stuff like that, and photographs and all that, and all I could think was 'I do not have that'. So I always wanted to escape that feeling, that loneliness. And then one day I broke my elbow when I was skating and they prescribed me with Hydromorphone for the pain, and that became my escape.",
            title: "P.02",
          },
          {
            text: "And I was just rough growing up and I know that's the case for lot of us, we're victims of violence and abuse, and that's why a lot of us use drugs, we medicate our feelings, to kind of get away, and forget. In the 18 years that I was using, no tear came down my eye for any reason.",
          },

          {
            text: "But I'm a father now and I love my daughter to death. She's actually the main reason why I want to stay clean. I just said to myself 'let me get myself together, you know, just not for me but for my daughter.' Because she needs a father that can be there for her.",
            title: "P.03",
          },

          {
            text: "So, I went to a clinic and I started a MAT, that's uh, medication assisted treatment. And I give them a lotta props, because the people at this place, they never judged me, they cared for me. And you know what, I'm starting to feel stuff, you know, the happiness, the sadness, the anger, the love. I'm feeling and I want to keep feeling, even if it hurts.",
          },

          {
            text: "I know sometimes you can only think about your next fix. And that's ok, because little by little you can start looking forward for something, family, friends, dating. You know just, look forward to crying like, allow yourself to feel.",
            title: "P.04",
          },
          {
            text: "Cuz if you're feeling sad, that's what comes right before the good part.",
            style: "callout",
          },
        ],
      },
      {
        name: "Meperidine",
        genericName: "Meperidine",
        company: "Pfizer",
        slug: "d37",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/d37.png",
        model: {
          file: "../../assets/models/21_D37.glb",
          scale: 6,
        },
        phone: "855–469–86–7848–(337)",
        strength: "100MG",
        strengthValue: 100,
        strengths: "5, 50, 100, 250, 375, 600mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1942",
        color: "white",
        popularity: 270,
        colors: { primary: "#DCDFD3", secondary: "#434639" },
        person: { name: "Mark" },
        audio: "../../assets/sound/337-(D37)-Mark-Meperidine-V1.mp3",

        script: [
          { text: "D37.", title: "P.01", style: "pill-title" },
          {
            text: "Why not D36, D38? Nah, doesn't make a difference.",
          },
          {
            style: "callout",
            text: "I'm Mark, let me tell you how I quit Meperidine.",
          },

          {
            text: "I'm 33, I was a construction worker, still am, and you know how it is, right? Demanding, heavy, but I, I like it. But man, everybody uses, it's like the only way you can cope, and it's just, it's socially acceptable, you know what I mean?",
            title: "P.02",
          },
          {
            text: "I stayed away from drugs but then I injured my back, bad, so I got surgery and they gave me Meperidine while I recovered. Boom, man. When your prescription runs out you still want more, and after that, well, game over.",
          },

          {
            text: "Shit got outta control. For real. 5 years down the drain I tried to recover, really, but the dope-sickness – I don't wish that on anyone. One day, I met a man I worked with, he was an addict, but a functional one. And he did it man, he quit, so he helped me out and became my, umm, my angel, man.",
            title: "P.03",
          },

          {
            text: "He got me writing on a journal, as therapy. Maaan, I was skeptical at first, but that shit really works. You pour your heart out, like never before. Try it, who knows? So I wrote everyday, I stopped hanging out around drugs, I got another job, you know, baby steps. I got meds for the dope-sickness and I said &quot;suck it up&quot;. You have to be in it, like in, in.",
          },

          {
            text: "I'm not the best at giving advice man, I just want to share with you that I quit, like my friend did with me. And if you stop hanging around people who use, and get closer to those who don't,",
            title: "P.04",
          },
          {
            text: "you will believe it's possible too. Trust me.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxycodone",
        genericName: "Oxycodone",
        company: "Midlothian<br />Laboratories LLC",
        slug: "54199",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/54199.png",
        model: {
          file: "../../assets/models/17_54199.glb",
        },
        phone: "855–469–86–7848–(54199)",
        strength: "30MG",
        strengthValue: 30,
        strengths: "10, 15, 20, 30, 40, 60, 80mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "blue",
        popularity: 180,
        colors: { primary: "#CDD8E4", secondary: "#363F49" },
        person: { name: "Hannah" },
        audio: "../../assets/sound/54199-(54199)-Hannah-Oxycodone-V1.mp3",

        script: [
          { text: "54199.", title: "P.01", style: "pill-title" },
          {
            text: "It's such a mouthful. I never remember the number, i just recognize it. Let me introduce myself,",
          },
          {
            style: "callout",
            text: "I'm Hannah and yes, this is how I quit Oxy.",
          },

          {
            text: "I grew up in a very abusive household, like physically, mentally, emotionally, and I was a very confused kid because, because my step dad and my half-brother were molesting me at the same time.",
            title: "P.02",
          },
          {
            text: "My dad was like in and out of my life and he was an addict. So, when I was 11, my dad introduced me to like drugs and alcohol, and I started drinking and doing Oxy and before I knew it, I was like doing it every day.",
          },

          {
            text: "By 20 I had tried everything, even trying to quit. Like detox in Florida, rehab in Philly, and then from Jersey I AMA'd, you know, like leaving against medical advice.",
            title: "P.03",
          },

          {
            text: "Then one day a boyfriend overdosed next to me, and I didn't even notice. So then I really tried to quit, and like I became a big fan of harm reduction, like they gave me clean needles, Suboxone, without pushing it on me.",
          },
          {
            text: "Even if I relapsed, they were still there for me. They made me see the power we all have to help each other out, which is why I'm telling you my story. I'm 3 months clean now, and I quit Oxy, weed, coke, heroin, crack, and alcohol.",
          },

          {
            text: "Don't blame yourself, cause that's what we do. Addicts. We blame ourselves for everything and that's what kills you. Holding on to that guilt and shame, it will make you look for drugs, and it's not your fault, at all, so like,",
            title: "P.04",
          },
          {
            text: "talk to people who understand that, and you'll see how it goes.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxycodone",
        genericName: "Oxycodone",
        company: "Inspirion<br />Delivery Sciences, LLC.",
        slug: "idto15",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/idto15.png",
        model: {
          file: "../../assets/models/13_IDTO15.glb",
          scale: 6,
        },
        phone: "855–469–86–7848–(438615)",
        strength: "15MG",
        strengthValue: 15,
        strengths: "5, 15, 30mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "green",
        popularity: 140,
        colors: { primary: "#E4E0CD", secondary: "#494636" },
        person: { name: "Marissa" },
        audio: "../../assets/sound/437615-(IDTO15)-Marissa-Oxycodone-V1.mp3",
        script: [
          { text: "IDTO15.", title: "P.01", style: "pill-title" },
          {
            text: "More like, idiot-15, this was always kind of a reminder of that.",
          },
          {
            style: "callout",
            text: "Hi, I'm Marissa and I quit Oxy, yeah.",
          },

          {
            text: "Oxy took away like my whole teenage life. Like one second you're in pain from an ankle injury and the next, the pain is completely gone, and not only that but it makes your whole life seem like it's amazing. And just like that, you lose your power of will, yeah.",
            title: "P.02",
          },
          {
            text: "But you're young and you can't afford it and you can't ask your parents for money, and suddenly you're introduced to cheaper, faster options. So, yeah.",
          },

          {
            text: "Being sober and staying sober wouldn't be such an amazing celebration, if we didn't realize that the other alternative is death. So after a few close encounters with death, it was very clear to me that I had to make a decision.",
            title: "P.03",
          },

          {
            text: "I tried NA meetings, but they're very old fashioned, like, you can't even be on medication, like Suboxone or Methadone, you know? Or else, you can't even be there. So ummm, I found the Drexel community, a harm reduction clinic. And since then, they've been helping me to stay off heavy substances, giving me medication, counseling me, and I've been good for 10 months now. 10 months! I'm so happy!",
          },

          {
            text: "In a way, I can – we can relate to what you must be feeling. Body and mind just needing you to take more. But every now and then there's a moment when you get tired of it, you aspire for more. And, that right there, is hope,",
            title: "P.04",
          },
          {
            text: "hope of a better life, and a better you.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxymorphone Hydrochloride",
        genericName: "Oxymorphone<br />Hydrochloride",
        company: "KVK-Tech<br />Inc.",
        slug: "k71",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/k71.png",
        model: {
          file: "../../assets/models/11_K 71.glb",
          scale: 5.6,
        },
        phone: "855–469–86–7848–(571)",
        strength: "10MG",
        strengthValue: 10,
        strengths: "5, 7.5, 10, 15, 20, 30, 40mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1959",
        color: "pink",
        popularity: 200,
        colors: { primary: "#E4CDD9", secondary: "#493640" },
        person: { name: "Bianca" },
        audio: "../../assets/sound/571-(K71)-Bianca-Oxymorphone-V1.mp3",

        script: [
          { text: "K71.", title: "P.01", style: "pill-title" },
          {
            text: "Kinda sounds like a gun, right? You know like AK47 or something like that. I mean, they both kill.",
          },
          {
            style: "callout",
            text: "Hi, I'm Bianca and I quit Oxy.",
          },

          {
            text: "I was 10, or 11 years old, when you know life gets a bit scary, I told my parents 'I think something's wrong with me'. So they ended up taking me to a psychologist, and they ended up putting me on Xanax. And then later when I was 12 I got diagnosed with something called um, endometriosis and also polycystic ovarian syndrome, which is basically fancy words for saying that my uterus and my ovaries hate me. And yeah, then they immediately started me on painkillers. Umm Oxymorphone to be specific.",
            title: "P.02",
          },
          {
            text: "Uh, yeah, so at that point I just had medication for every hour of my life, and that grew into, kind of like my coping mechanism for everything. Like, you shouldn't give Oxymorphone to someone that young, I mean, my entire body was in pain when I wasn't taking these pills.",
            title: "P.03",
          },

          {
            text: "And it took me a while, but I was probably in like five or six different treatment centers, and, and I had been to the ones, you know where they put little chocolates on your pillows and I've also been to ones where there's cockroaches crawling around everywhere, you know. Like, but it wasn't until somebody pulled me aside and they said 'okay you can't run your life right now, so how about you let someone else run it for you, just for a little bit, and see how that works'. And I just kind of gave up and said, 'yeah, okay'.",
          },

          {
            text: "And that program did teach me how to grow up, it was that, plain and simple. Like I was such a child and I, I needed someone to hold my hand until I learned to live on my own again, and honestly I'm happy I did, and I now want to offer that to you.",
            title: "P.04",
          },
          {
            text: "Let's find out how you want to live.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Hydrocodone Bitartrate",
        genericName: "Acetaminophen and<br />Hydrocodone Bitartrate",
        company: "Camber<br />Pharmaceuticals, Inc.",
        slug: "t258",
        shape: "../../assets/images/pills/pill-shape-ellipse.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/t258.png",
        model: {
          file: "../../assets/models/12_T 258.glb",
          scale: 6,
        },
        phone: "855–469–86–7848–(8258)",
        strength: "7.5MG",
        strengthValue: 7.5,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1982",
        color: "white",
        popularity: 60,
        colors: { primary: "#DCDFD3", secondary: "#434639" },
        person: { name: "Sammy" },
        audio: "../../assets/sound/8258-(T258)-Sammy-Hydrocodone-V1.mp3",
        script: [
          { text: "T258.", title: "P.01", style: "pill-title" },
          {
            text: "Hi, this is Sammy, and this is how I quit hydrocodone T258. And to me, it was pretty symbolic because it made me realize, or made me think of the same 24/7. So, it felt like I was working around the clock, uh, in order to fulfill that like, need and desperation.",
          },
          {
            style: "callout",
            text: "Hi, this is Sammy, and this is how I quit hydrocodone T258.",
          },

          {
            text: "That's exactly what life was like when I was under the influence on hydrocodone, and trying to chase that high, the desperation, not enough hours in the day.",
            title: "P.02",
          },
          {
            text: "Yeah, so I was actually prescribed hydrocodone because I fractured the right foot playing soccer. Um, soccer is my passion, and it was my sophomore year. I was about maybe 15 or 16 years old. And, uh, I, I was taking it as prescribed, but then I liked the effect produced by it. So I was like, 'hmm, I wonder what it'll feel like if I took two or three'. And then it was just a, a, a series of Curious George, and then it became Xanax, and quickly from Xanax it, it became Black Tar Heroin. Little did I know I was just building this huge, uh, problem with addiction.",
          },
          {
            text: "I kept my addiction up, um, which was primarily towards the end more Heroin and meth and a little bit of fentanyl. Umm, yeah, at that stage things got bad for sure. You know, family no longer wanted to speak with me, multiple trips to treatment, dropped out of college, umm, living from motel to motel, trying to figure out where I was going to sleep.",
          },

          {
            text: "You know family no longer wanted to speak with me multiple trips to treatment Dropped out of college, um, living from motel to motel, trying to figure out where I was going to sleep. I was still willing to, to suffer the consequences.",
            title: "P.03",
          },
          {
            text: "I went to multiple treatment centers and, uh, I was 25-years-old. And it was my brother's last attempt to help me because at this time I was already an IV user. And, uh, it was his last attempt because he could see that I was gonna basically overdose soon. And he told me there was an open bed to a treatment center. And to me at the time, I said, Okay. And right before I checked myself into that detox, I ended up overdosing, got rushed to the hospital, ended up in the ICU over the weekend because I had bacteria in my blood. And on that hospital bed is when I had like my moment of clarity where I was like I basically got brought back before I took that pill when I was 15 years old, and I started crying like a baby. Cause I was like, Sammy, like, what are you doing with your life? Like, you could be a healthy 25-year-old, but instead you're in the ICU.",
          },
          {
            text: "Like that moment I knew like,the gig was up, and I was just like, you know what? I'm finally going to take suggestions from the people that are trying to help me. And I went to that detox after I got discharged from the hospital. I stayed the full time, I went to treatment. And from that treatment, you know, I got introduced to, umm, the rooms, they say, or like a 12-step program.",
          },
          {
            text: "And thankfully, that's where I found some, uh, people that I could relate with. And, uh, here we are five and a half years later uh, still sober.",
          },

          {
            text: "Yeah, having a sober community is honestly the, one of the main reasons I'm still sober today. You can find a group of people that you relate with and like, there's a beautiful life waiting for you, outside of your addiction.",
            title: "P.04",
          },
          {
            text: "You are not your addiction and, and it is possible to overcome it and live a completely different life that you would have never thought was possible.",
            style: "callout",
          },
        ],
      },
      {
        name: "Oxymorphone Hydrochloride",
        genericName: "Oxymorphone<br />Hydrochloride",
        company: "Sun Pharmaceutical<br />Industries Inc.",
        slug: "187",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/187.png",
        model: {
          file: "../../assets/models/10_187.glb",
        },
        phone: "855–469–86–7848–(187)",
        strength: "15MG",
        strengthValue: 15,
        strengths: "5, 10, 15, 20, 30mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "green",
        popularity: 150,
        colors: { primary: "#CEE4CD", secondary: "#374936" },
        person: { name: "Ronny" },
        audio: "../../assets/sound/187-(187)-Ronny-Oxycodone-V1.mp3",
        script: [
          { text: "187.", title: "P.01", style: "pill-title" },
          {
            text: "That’s the number of the green little pill which contained 15 mgs of what I thought was love, safety, and energy.",
          },
          {
            style: "callout",
            text: "My name is Ronny and this is how I quit oxycodone, I call them the green 15s.",
          },

          {
            text: "Um, it started pretty innocently, I guess you could say, with a car accident that left me with excruciating pain. Uh the doctor prescribed me Percocet to manage it for a while. And it worked like magic. I mean, I had no idea what this feeling was that I felt, but immediately the physical pain went away, but at the same time I felt an overwhelming sense of security and joy. Three months of the prescription were my go-to, right.",
            title: "P.02",
          },
          {
            text: "The doctor prescribed me three months and then after that they abruptly stopped it. But by that time, I was physically and mentally dependent. Umm, but because I wasn't prescribed them anymore I had to find them out on the streets – and that's what I did. I traded my values for them. I sold my camera. I lied to my family. Every paycheck uh was simply just to get the pills to go to work, to get the pills to go to work.",
          },

          {
            text: "Ummm, but there came a point where I truly realized I, I couldn't go on like this anymore after I had a lost my connections with family. my apartment, uh my dream job.",
            title: "P.03",
          },

          {
            text: "So how did I quit? I mean, it wasn't easy, that's for sure. After years of trying to go to local meetings, therapies, and rehabs, umm it wasn't until I told myself enough is enough. A mind made up is the most powerful thing.",
          },
          {
            text: "One step at a time, I was able to change my life. And slowly but surely, I started to see glimpses of the person I used to be before I took these pills over and over.",
          },

          {
            text: "It wasn't easy, there were setbacks along the way, but with each passing day I grew stronger and – over time I've been able to change my life. I'm married, I live in a house with my partner, I have a great job and you know, have a new car, and those are all superficial things, but those are all things that I truly cannot imagine being able to obtain while trapped in the grips of addiction due to these pills. Umm, so here I am no longer defined by those numbers, no longer a slave to those green 15s.",

            title: "P.04",
          },
          {
            text: "I am Ronny and I am free.",
            style: "callout",
          },
        ],
      },
      {
        name: "Morphine Sulfate Extended-Release",
        genericName: "Morphine Sulfate<br />Extended-Release",
        company: "Mayne<br />Pharma Inc.",
        slug: "ml",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/ml.png",
        model: {
          file: "../../assets/models/9_ML.glb",
          scale: 7,
        },
        phone: "855–469–86–7848–(65)",
        strength: "60MG",
        strengthValue: 60,
        strengths: "10, 12, 20, 30, 45, 50, 60, 75, 80, 100, 200mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "purple",
        popularity: 240,
        colors: { primary: "#E4CDE3", secondary: "#493648" },
        person: { name: "Sam" },
        audio: "../../assets/sound/65-(ML)-Sam-Morphine-V1.mp3",

        script: [
          { text: "ML.", title: "P.01", style: "pill-title" },
          {
            text: "Milliliters, that's all it takes. A little sip and then you're knees deep.",
          },
          {
            style: "callout",
            text: "My name is Sam and I, I quit Morphine.",
          },

          {
            text: "I was going through some problems at home, my parents had just gotten divorced, and umm, I was the only boy in the family in a family of five other sisters, and it's just – I really leaned heavily on this, umm – on this new world I discovered in drugs. I uh, was drinking, smoking weed throughout my early teens, and didn't experience any serious consequences, didn't feel like I was doing anything wrong at the time. And I progressively started doing other drugs, hallucinogens, ecstasy, and the whole part – the party scene. And one day a friend stole some Morphine from his dad's cabinet and... well I just didn't realize it was gonna lead me into you know, digging myself into a hole that I couldn't get out of. It's, umm, you get addicted to the feeling of it, the carefree, you know?",
            title: "P.02",
          },

          {
            text: "It came to a point where, ummm, I had a very low image of myself. Ummm, it, it's a hard – it's a harsh truth to swallow. You know and I could see at one point that my life was over and I hadn't even even lived yet, I was still a teenager, I was, you know, 17.",
            title: "P.03",
          },

          {
            text: "I met someone my same age that was, like, also thinking about quitting. And it was an amazing feeling you know? I didn't – I didn't know that there were people out there just like me, and I, I had a moment right there of of 'ok, I need help'.",
          },

          {
            text: "We joined a program together and stayed in it for 6 months. It gave me a, uh, hope in my life, finally. I've been sober now for 4 years and 3 months? And I never thought that that would be possible.",
          },

          {
            text: "You know for me it was umm, I could finally not carry the guilt of how bad a son I am, a brother, and what a terrible person of society I am. I get to walk around knowing that my dad is proud of me and my, my sisters are proud to call me their brother, and they can actually depend on me to do, what I say I'm gonna do.",
            title: "P.04",
          },
          {
            text: "And I wish the same for you, that we can all now just, show up for life.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Hydrocodone Bitartrate",
        genericName: "Acetaminophen and<br />Hydrocodone Bitartrate",
        company: "KLE 2<br />Inc.",
        slug: "kle",
        shape: "../../assets/images/pills/pill-shape-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/kle.png",
        model: {
          file: "../../assets/models/8_KLE.glb",
          scale: 3.5,
        },
        phone: "855–469–86–7848–(553)",
        strength: "2.5MG",
        strengthValue: 2.5,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1982",
        color: "white",
        popularity: 10,
        colors: { primary: "#D3D9DF", secondary: "#394046" },
        person: { name: "Shantal" },
        audio: "../../assets/sound/553-(KLE)-Shantal-Hydrocodone-V1.mp3",

        script: [
          { text: "KLE.", title: "P.01", style: "pill-title" },
          {
            text: "Funny that each letter is the initial for a different drug, huh.",
          },
          {
            style: "callout",
            text: "My name is Shantal and I quit Hydrocodone.",
          },

          {
            text: "I was homeschooled, that allowed to me to go do gymnastics for about five hours a day for 10 years? I graduated high school at 16 and was able to start college early. I figured that, you know, I'm like way ahead of everybody soo, I can party, let loose and let everyone else catch up to me.",
            title: "P.02",
          },

          {
            text: "But then one day I was on my bike and I got hit by a fucking SUV, so I was prescribed Hydros, and from there it progressed from 1 pill a day, to 6, to 15 pills a day...",
          },
          {
            text: "And then I run out and my boyfriend at the time was like 'oh you should try opium' and I just didn't know, like a fucking idiot, that that was heroin so I smoked it and it was fucking amazing.",
          },

          {
            text: "So yeah, it escalated from there. One day I was in this house and it got raided, so I ate a plastic bag with everything I had, thinking I could puke it up later, but I OD'd. Umm, I mean they revived me but, I don't know I ended up with blood poisoning, and no one thought I was going to make it.",
            title: "P.03",
          },

          {
            text: "And after that my family forced me into treatment and once I actually spent a lot of time in therapy, resolving it, and fitting all the issues that I had that you know – where my drug use was stemming from, I was able to work through it. And I also explored things that just made me happy, like yoga.",
          },

          {
            text: "Basically, get to know yourself and find everything that you can to make yourself feel good, so you don't have a reason to use. You'll always crave it, sure, but you will be grateful everyday for everyday that you're sober.",
            title: "P.04",
          },
          {
            text: "It might be a little bit boring sometimes, but it's peaceful.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Hydrocodone Bitartrate",
        genericName: "Acetaminophen and<br />Hydrocodone Bitartrate",
        company: "Lannett<Br />Company, Inc.",
        slug: "lci",
        shape: "../../assets/images/pills/pill-shape-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/lci.png",
        model: {
          file: "../../assets/models/7_LCI.glb",
          scale: 5.8,
        },
        phone: "855–469–86–7848–(524)",
        strength: "5MG",
        strengthValue: 5,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1982",
        color: "pink",
        popularity: 40,
        colors: { primary: "#E4D3CD", secondary: "#493B36" },
        person: { name: "Brandi" },
        audio: "../../assets/sound/524-(LCI)-Brandi-Hydrocodone-V1.mp3",

        script: [
          { text: "LCI.", title: "P.01", style: "pill-title" },
          {
            text: "I always thought it sounds kinda fancy. Would think of a college or something, never a pill.",
          },
          {
            style: "callout",
            text: "I am, Brandi, and yes, this is how I quit the Hydros.",
          },

          {
            text: "I discovered at an early age that I was a bit of a trouble maker, like mixing Tequila with Dr. Pepper. And then, when I was like 19 I was in the car with my dad and we had this horrible accident. So after spending some days in the hospital they prescribed both of us, acetaminophen laced with Hydrocodone, pain killers. So back at home we even reminded each other to take the pills at a certain hour, until it became a habit.",
            title: "P.02",
          },

          {
            text: "After a few months my dad stopped taking them and I should have, adopted his way of thinking a long long time ago. But I just thought it was really cool, and the – I didn't see how, how such great feeling and an actual medicine, could fuck up the rest of my life. ",
          },

          {
            text: "My doctor stopped my prescription, just as I landed a job down at Alamo, the rent-a car, and that place was tweaker paradise, everybody there was using meth. So I thought, why not? And, um, after that it was a decade of addiction.",
            title: "P.03",
          },

          {
            text: "I tried to quit many times, but the real moment came when, um, so, my mom was diagnosed with Alzheimer's way back, and it got to a point where her main memory of me was, the addict version of me, and it, it broke my heart.",
          },

          {
            text: "So yeah, I went through treatment at Crossroads, and uh, I, I just absorbed everything that they gave me, like a giant sponge. I even wrote a puppet musical called Straight Out Of Rehab, and this, expression, has been such an important part in my recovery.",
          },

          {
            text: "The more positive self-talk you can get in, every day, the more you welcome um, like, recovery discoveries, the more you realize this change makes everyone you love, happier,",
            title: "P.04",
          },
          {
            text: "then the easier it gets, to eliminate doubt, you know.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Oxycodone Hydrochloride",
        genericName: "Acetaminophen and<br />Oxycodone Hydrochloride",
        company: "Alvogen<br />Inc.",
        slug: "229",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/229.png",
        model: {
          file: "../../assets/models/5_C 229.glb",
          scale: 5.2,
        },
        phone: "855–469–86–7848–(229)",
        strength: "7.5MG",
        strengthValue: 7.5,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1976",
        color: "orange",
        popularity: 110,
        colors: { primary: "#E4DACD", secondary: "#494136" },
        person: { name: "Jon" },
        audio: "../../assets/sound/229-(229)-Jon-Oxycodone-V1.mp3",

        script: [
          { text: "229.", title: "P.01", style: "pill-title" },
          {
            text: "That was the same number of the bus I took every single morning to go to work. I always took it as a sign, to use. Now I know it was the opposite of that. ",
          },
          {
            style: "callout",
            text: "I'm Jonny and um, this is how I quit Oxy.",
          },

          {
            text: "As a young young child I loooved sports, like, like martial arts. I just loved how it made me feel.",
            title: "P.02",
          },

          {
            text: "And once when I was like 14 or 15, a friend asked me to show him some martial arts, techniques. And so I jumped up and I, I did like a really uh, a wild kick. And when I landed back on the grass, the grass was wet, and so my body weight went to the right, and my knee just went completely out to the left and just like exploded on his front lawn.",
          },

          {
            text: "That event was umm, like I don't know if I ever would have taken painkillers if it wasn't for that, but it got me started man.",
          },

          {
            text: "So now, I'm on Oxy and tst, a year passes and I don't need it anymore, but I still want some. And my one friend who was like a using buddy, he said &quot;oh Jonny I don't even do pills anymore, I do dope now cause it's cheaper and it gets me higher, and it lasts longer&quot;. And to me in that moment, I was like, there's no downside.",
            title: "P.03",
          },

          {
            text: "Cut to 5 years later, and I get arrested for the use and sale of drugs. The police knew everything about me. And I'm there at the station, thinking that as soon as I get out I'm going to use, but I turn around and see my mother's face, I'll never forget that. She looked heart broken, um, helpless. I can't describe it but I felt this, this burden, like my chains breaking, and I was offered 18 months of intensive outpatient rehab, and if you mess up you go to jail. And I needed the burden of those consequences, to push through.",
          },

          {
            text: "My appreciation for life is greater than it ever would've been if I didn't go through what I went through. I was the missing piece, not my life.",
            title: "P.04",
          },
          {
            text: "Don't ever be ashamed of this disease, it's just like any other, something",
          },
          {
            text: "you can heal with the right tools, but it's up to you to make that call.",
            style: "callout",
          },
        ],
      },
      {
        name: "Acetaminophen and Oxycodone Hydrochloride",
        genericName: "Acetaminophen and<br />Oxycodone Hydrochloride",
        company: "Camber<br />Pharmaceuticals, Inc.",
        slug: "t191",
        shape: "../../assets/images/pills/pill-shape-rectangle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/t191.png",
        model: {
          file: "../../assets/models/6_T 191.glb",
          scale: 5.5,
        },
        phone: "855–469–86–7848–(8191)",
        strength: "2.5MG",
        strengthValue: 2.5,
        strengths: "2.5/325, 5/325, 7.5/325, 10/325mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1976",
        color: "white",
        popularity: 80,
        colors: { primary: "#DFD3D3", secondary: "#463939" },
        person: { name: "Christin" },
        audio: "../../assets/sound/8191-(T191)-Christin-Oxycodone-V1.mp3",
        script: [
          { text: "T191.", title: "P.01", style: "pill-title" },
          {
            text: "You know, for a long time my whole life revolved around this little pill, but now that seems like a completely different life. Thank god.",
          },
          {
            style: "callout",
            text: "Hi, my name is Christin and this is how I quit Oxy.",
          },

          {
            text: "I come from a broken home. I was probably eight years old, when umm, my parents got a divorce, and it started like, a rocky path for me, you know. So I started seeking umm, something that allowed me to, to not feel – bad.",
            title: "P.02",
          },

          {
            text: "Drugs and alcohol were one thing, but the men is definitely my number one addiction. And so if you put all of these together, it's just, not a good mix. So every breakup, every abusive relationship, every fight, triggered my urge for alcohol and drugs. But then I had a miscarriage, because of all this – craziness, and I was prescribed, Oxy. And this pill, combined with my emotional state, it opened um, Pandora's box.",
          },

          {
            text: "Soon after, I got pregnant, but I wasn't, you know, like, ready for it. I had already moved on to stronger stuff, so like I started doing meth every day, so of course when my baby finally came I didn't know how to, how to, ugh, care for her. My baby girl was taken away from me and she was adopted by a couple, you know, with the white picket fence and all.",
            title: "P.03",
          },

          {
            text: "After like two years I got in touch with them and they allowed me to come visit, but they told me, 'you can't come if you're not clean'. I resented them at first, but I'm grateful that they did, because they pushed me to get in a program and, umm, do the whole thing. I did the group therapies, the medication, the Suboxone, I started going to church, and it took a while, but you know, now I have a relationship with my daughter, and that means the world to me.",
          },

          {
            text: "I cannot put into words how much life has changed, uh, working in recovery, and that, that right there, it's amazing. Sun up to sun down my life is definitely recovery, so now I dedicate my life to help people who are like me, and",
            title: "P.04",
          },

          {
            text: "if I can give you that little bit of hope, well, that's my only goal.",
            style: "callout",
          },
        ],
      },
      // {
      //   name: "Hydrocodone Bitartrate and Ibuprofen",
      //   genericName: "Hydrocodone Bitartrate<br />and Ibuprofen",
      //   company: "Qualitest<br />Pharmaceuticals",
      //   slug: "3585",
      //   shape: "../../assets/images/pills/pill-shape-circle.svg",
      //   gridStyle: 1,
      //   image: "../../assets/images/pills/3585.png",
      //   model: {
      //     file: "../../assets/models/4_3585.glb",
      //     scale: 6,
      //   },
      //   phone: "855–469–86–7848–(3585)",
      //   strength: "7.5MG",
      //   strengthValue: 7.5,
      //   strengths: "5/200, 7.5/200, 10/200mg",
      //   availability: "Prescription Only",
      //   csaSchedule: 2,
      //   potentialForAbuse: "high",
      //   approvalHistory: "First approved by FDA in 1997",
      //   color: "white",
      //   popularity: 50,
      //   colors: { primary: "#D3D5DE", secondary: "#393C46" },
      //   person: { name: "XXX" },
      // },
      {
        name: "Morphine",
        genericName: "Morphine",
        company: "Endo<br />Pharmaceuticals Inc.",
        slug: "e659",
        shape: "../../assets/images/pills/pill-shape-oval.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/e659.png",
        model: {
          file: "../../assets/models/3_E659.glb",
        },
        phone: "855–469–86–7848–(3659)",
        strength: "200MG",
        strengthValue: 200,
        strengths: "15, 30, 60, 100, 200mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1998",
        color: "green",
        popularity: 260,
        colors: { primary: "#D0E4CD", secondary: "#394936" },
        person: { name: "Chris" },
        audio: "../../assets/sound/3659-(E659)-Chris-Morphine-V1.mp3",
        script: [
          { text: "E659.", title: "P.01", style: "pill-title" },
          {
            text: "E659. Hmm, I just remembered its green color, I used to call it my green relief.",
          },
          {
            style: "callout",
            text: "Hi, I'm Chris and I quit Morphine.",
          },

          {
            text: "I guess what got, got me involved in drugs in the first place was, uuum, I was in an environment where there were a lot of drugs around. Growing up a lot of my friends' parents, had prescription pills for whatever reason, so it was common for us to hang out and um, smoke weed, and, um, take Morphine.",
            title: "P.02",
          },

          {
            text: "At first I don't even know if I did it for like, the sensation, but more for like the attention. But umm, fast forward a couple of years and it's no longer the attention, it's now about the substance. So what began as weed and Morphine, became Morphine and coke, then Morphine and meth, then just meth because it's cheaper, then heroin is in the picture, and after that, ummm, you- you just go downhill.",
          },

          {
            text: "Like many of us I, umm, so throughout all those years, even while being high, I never admitted I was a drug addict, ever. Ummm, but after many bad decisions, burglaries, thefts, forgeries, umm and three prison sentences, I, for the first time, I asked for help. And help was given to me.",
            title: "P.03",
          },

          {
            text: "You know I was given treatment, I was given uh, you know the ability to go to Sober Living afterward and, to start a program of recovery, and uh working on my spiritual self really. Through that came a thought, that if I don't get high, I won't do crimes, and if I don't do crimes, I don't go to prison. And you know, I'm not a dummy, you would think that, that would've been a thought long ago, but it never even popped in my mind one iota.",
          },

          {
            text: "Through working the steps and going to, uh, to therapy, and talking with someone, you have these sort of epiphanies. So uh, ",
            title: "P.04",
          },
          {
            text: "learn to listen to yourself, to your needs, listen to someone who wants to help, and once you do, just talk.",
            style: "callout",
          },
          {
            text: "There's a lot of people willing to now sit back and listen. Cause at the end of the day, it's all about that, so umm, thank you for listening.",
          },
        ],
      },
      {
        name: "Hydromorphone Hydrochloride Extended-Release",
        genericName: "Hydromorphone Hydrochloride<br />Extended-Release",
        company: "Camber<br />Pharmaceuticals, Inc.",
        slug: "268",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 2,
        image: "../../assets/images/pills/268.png",
        model: {
          file: "../../assets/models/2_268.glb",
          scale: 6.2,
        },
        phone: "855–469–86–7848–(268)",
        strength: "16MG",
        strengthValue: 16,
        strengths: "2, 4, 8, 12, 16, 32mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "biege",
        popularity: 330,
        colors: { primary: "#E4D9CD", secondary: "#494036" },
        person: { name: "Sam" },
        audio: "../../assets/sound/268-(268)-Sam-Hydromorphone-V1.mp3",
        script: [
          { text: "268.", title: "P.01", style: "pill-title" },
          {
            text: "Yeah like you first think, just 2 a day, and 6, and then 8, and then as many as it takes.",
          },
          {
            style: "callout",
            text: "I'm Sam and um, I quit Hydros.",
          },

          {
            text: "I was born into a broken family. I guess I was uh, misplaced from my home, um, when I was about 6 months old, and I ended up getting passed around from home to home. I think I had around 15 different homes by the age of 10. So I, uh, grew up being very insecure, you know.",
            title: "P.02",
          },

          {
            text: "But around that time that I finally landed in what I can now call my parents home and as I was learning to ride a bike I had a little mishap, so I got prescribed, opioids, you know, Hydros. And everybody at school knew I had pills, so it kinda became, uh, my way to, to connect with people, to not feel self conscious or, or insecure.",
          },

          {
            text: "And naturally after a few years where I still had pills I uh, I just guess I got hooked to that feeling.",
          },

          {
            text: "Addiction is just soo strong. You know you shouldn’t, but when you have a pill in your hand or whatever, it’s already too late.",
            title: "P.03",
          },

          {
            text: "At this point I was like, 'I wanna use and I don't know how to uh, turn it off! Like I don't know what to do'. I ended up, within that time frame, I ended up having a suicide attempt, I had uh 36 Hydrocodone pills. I blacked out for 2 days.",
          },

          {
            text: "After that I don't know where to go, I don't have no money and no insurance, and someone told me 'go to CBI'. So I went there and I ended up getting admitted to detox, with flying colors. They didn't look at me with judgement, they didn't judge me for my actions in the beginning, you know? Screaming and yelling at them. They took me under their wing.",
          },

          {
            text: "That experience, it changed my heart. I stopped flipping out and getting angry at people and it taught me to, um, I started to try and understand people in the same way. To listen, you know? Like you're listening to me.",
            title: "P.04",
          },
          {
            text: "Just open your mind and your heart, because a lot of us are here to help you, wherever you may be.",
            style: "callout",
          },
        ],
      },
      {
        name: "Hydromorphone Hydrochloride",
        genericName: "Hydromorphone<br />Hydrochloride",
        company: "Roxane<br />Laboratories, Inc.",
        slug: "54425",
        shape: "../../assets/images/pills/pill-shape-circle.svg",
        gridStyle: 1,
        image: "../../assets/images/pills/54425.png",
        model: {
          file: "../../assets/models/1_54 425.glb",
          scale: 7,
        },
        phone: "855–469–86–7848–(54425)",
        strength: "8MG",
        strengthValue: 8,
        strengths: "2, 4, 8, 12, 16, 32mg",
        availability: "Prescription Only",
        csaSchedule: 2,
        potentialForAbuse: "high",
        approvalHistory: "First approved by FDA in 1984",
        color: "white",
        popularity: 310,
        colors: { primary: "#DEDED3", secondary: "#464639" },
        person: { name: "Carlitos" },
        audio: "../../assets/sound/54425-(54425)-Carlitos-Hydromorphone-V1.mp3",
        script: [
          { text: "54425.", title: "P.01", style: "pill-title" },
          {
            text: "54–425. Huh. Yeah I've been there, holding the pill with some numbers that you never remember. One too many times carajo.",
          },
          {
            style: "callout",
            text: "I'm Carlitos and this is how I quit Hydromorphone.",
          },

          {
            text: "I – I felt like I couldn't escape, this, really. Everyone I knew had opioids on them. My friends, they took or they sell. My brother actually died of an overdose. My favorite artists, they mostly sung about drugs. So as soon as you have one in front of you, you, umm, you take it. So my first time, for example, I was around 12 and I tried Hydromorphone – whooof.",
            title: "P.02",
          },
          {
            text: "After that first one, ahhhh, I just couldn't stop. It was just that good. And the rest follows soon after, coke, meth, heroin – the bad decisions.",
          },

          {
            text: "That led to 20-long-years hermano. And after a friend overdosed next to me, I tried rehab, but I relapsed. And then my brother died, so I tried detox but the withdrawal, it put me back.",
            title: "P.03",
          },

          {
            text: "Oh then after this I was just so desperate. I wanted treatment but man, I just didn't want to feel sick, you know? I hate that. And I couldn't afford this new fancy medicine Suboxone and Methadone. But I finally found a free clinic for people with low income. And they helped me, they gave me the substitute opioids, they gave me a home, they gave me a good doctor, and they actually gave me friends that wanted to quit and boy did that make difference. Like, literally it gave me a second chance at life.",
          },

          {
            text: "And while I was there I remember seeing this quote &quot;Find what you love and let it kill you&quot;, and for a long while I thought it meant drugs, but nah, that's not love, it's obsession. I think it's about life, and if you love life and you let it little by little kill you, you'll die a happy man, I promise you that.",

            title: "P.04",
          },
          {
            text: "And right now, all it takes is a single dial, so go ahead.",
            style: "callout",
          },
        ],
      },
    ],
  },

  background: {
    meta: {
      slug: "background",
      title: "Background",
      description:
        "The helpline that turns the object of addiction into a way out.",
    },
    title:
      "history of<br />opi<img class='title-icon' src='../../assets/images/background/background-title-icon.svg' />ids",

    blocks: [
      {
        type: "p",
        style: "indent-block",
        text: "Opioids are a class of drugs that derive from, or mimic, natural substances found in the opium poppy plant. Opioids work in the brain to produce a variety of effects, including pain relief. As a class of substances, they act on opioid receptors to produce morphine-like effects.",
      },
      {
        type: "full-image",
        style: "full-image",
        image: "../../assets/images/background/background-image-1.jpg",
        imageMobile:
          "../../assets/images/background/background-image-1-mobile.jpg",
        caption:
          "Rachel and Pat developed a dependency to opioids after a doctor prescribed them for Rachel’s illness. (Stockbridge, 2012)",
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "what", style: "left indent-2" },
          { text: "are opioids?", style: "left" },
        ],
      },
      {
        type: "p",
        style: "indent-block-3",
        label: "P01.1",
        text: "Opioids are a class of drugs that alleviate pain and may produce a pleasurable effect on the brain and body. They may be prescribed by a health-care professional as part of treatment following an injury or surgery, or to treat moderate to severe pain caused by an illness.",
      },
      {
        type: "p",
        style: "indent-block-2",
        label: "P01.2",
        text: "During the past couple of decades there has been an ongoing debate about their use and availability for medical purposes. Some believe that not all opioids should be banned, placing the blame on misguided prescription practices. This, however, has caused widespread confusion among the general public.",
      },

      {
        type: "title",
        style: "titles",
        titles: [
          { text: "OPIATES", style: "left indent-2 medium-size" },
          { text: "OR OPIOIDS?", style: "left indent-2 medium-size" },
        ],
      },

      {
        type: "p",
        style: "indent-block-2",
        label: "P01.3",
        text: "This confusion starts with the name and a definition. Although these terms are often used interchangeably, they are different. To make it simple: all opiates are opioids, but not all opioids are opiates.",
      },
      {
        type: "box-row",
        style: "box-row",
        boxes: [
          {
            title: "OPIOID",
            subTitle: "/ˈəʊpɪɔɪd/",
            text: "Narcotic analgesics that are at least part synthetic:",
            labels: [
              "Meperidine",
              "Hydrocodone",
              "Oxycodone",
              "Hydromorphone",
              "Fentantyl",
            ],
            style: "outline",
          },
          {
            title: "OPIATE",
            subTitle: "/ˈəʊpɪət/",
            text: "Narcotic analgesics derived from an opium poppy (natural)",
            labels: ["opium", "morphine", "codeine", "heroin"],
            style: "black",
          },
        ],
        caption: "Distinction between commonly known opioids and opiates.",
      },
      {
        type: "image-row",
        style: "two-col",
        cols: [
          {
            imageCaption:
              "Natural opium harvesting. (Bocsi in Bloomberg, 2014)",
            image: "../../assets/images/background/background-image-2.jpg",
          },
          {
            imageCaption:
              "Synthetic opioid production. (Aung Thu in AFP, 2019)",
            image: "../../assets/images/background/background-image-3.jpg",
          },
        ],
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "THE MOST COMMONLY", style: "left  medium-size" },
          { text: "ABUSED OPIOIDS", style: "left  medium-size" },
        ],
      },
      {
        type: "pill-table",
        style: "pill-table",
        headings: [
          "Imprint",
          "opioid",
          "types of pain it’s used for",
          "common use cases",
          "potency relative to&nbsp;morphine",
          "duration of&nbsp;action",
        ],
        pills: [
          {
            image: "../../assets/images/pills/ip33.png",
            opiod: "Codeine",
            types: "Mild to moderate short-term pain",
            use: "Cough and colds",
            potency: "1/8",
            duration: "2—4 h",
          },
          {
            image: "../../assets/images/pills/d37.png",
            opiod: "Meperidine",
            types: "Moderate to severe short-term pain",
            use: "Before or during surgery",
            potency: "1/10",
            duration: "3—6 h",
          },
          {
            image: "../../assets/images/pills/m365.png",
            opiod: "Hydrocodone",
            types: "Moderate to severe pain",
            use: "Cancer, physical injury or surgery",
            potency: "2/3",
            duration: "4—8 h",
          },
          {
            image: "../../assets/images/pills/pf.png",
            opiod: "Morphine",
            types: "Moderate to severe short-term or chronic pain",
            use: "Palliative care, cancer, childbirth, severe physical injury or surgery",
            potency: "1",
            duration: "3—6 h",
          },
          {
            image: "../../assets/images/pills/k9.png",
            opiod: "Oxycodone",
            types: "Severe short-term pain",
            use: "Cancer, chronic pain (if no other medication works)",
            potency: "1.5",
            duration: "3—4 h",
          },
          {
            image: "../../assets/images/pills/exh12.png",
            opiod: "Hydromorphone",
            types: "Moderate to severe short-term pain and severe chronic pain",
            use: "Cancer, severe physical injuries or surgery",
            potency: "2",
            duration: "4—5 h",
          },
          {
            image: "../../assets/images/pills/g73.png",
            opiod: "Oxymorphone",
            types: "Moderate to severe chronic pain",
            use: "Advanced cancer",
            potency: "3",
            duration: "12 h",
          },
          {
            image: "../../assets/images/pills/c.png",
            opiod: "Fentanyl",
            types: "Severe short-term pain",
            use: "Advanced cancer, severe physical injury or surgery, palliative care",
            potency: "100",
            duration: "72 h",
          },
        ],
      },
      {
        type: "brain-block",
        style: "",
        title: "EFFECT ON THE BRAIN",
        heading: "P01.04",
        text: "In the human brain there’s a collection of brain structures commonly referred to as the reward system. In it, dopamine, the “feel good” hormone, is released whenever something pleasurable is experienced. When it occurs naturally the neurotransmitters activate, creating a momentary feeling of happiness. But when released artificially, the brain is flooded with dopamine, sometimes causing a sense of euphoria. This phenomenon is what causes a dependence on opioids.",
        image: "../../assets/images/background/background-brain.png",
        imageMobile:
          "../../assets/images/background/background-brain-mobile.png",
        subtext:
          "An analysis of Positron Emission Tomography (PET) scans of the brain’s reward system. The warmer the color, the more activity there is. (Garcia et al., 2020)",
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "HISTORY OF", style: "left indent-1" },
          { text: "the OPIOIDS", style: "left" },
          { text: "epidemic", style: "right" },
        ],
      },
      {
        type: "p",
        style: "indent-block-3",
        label: "P02.1",
        text: "The year is 1991, and there’s a growing movement in the United States that claims the healthcare sector is undertreating pain. A belief fueled by patient advocacy groups and pharmaceutical companies, who funded research to emphasize the risks of undertreating pain. But behind this cause is a business opportunity.",
      },
      {
        type: "p",
        style: "inset bottom-1",
        label: "P02.2",
        text: "This medicinal initiative, combined with a series of regulatory mistakes made by the US Food and Drug Administration (<img class='image block-icon fda' src='../../assets/images/background/fda.svg' alt=''/>), kickstarted a three-decade human-made plague.",
      },
      {
        type: "wave",
        style: "wave-1",
        title1: "The First Wave:",
        title2: "PRESCRIPTION PILLS",
      },
      {
        type: "p",
        style: "indent-block-4",
        label: "P02.3",
        text: "Fast-forward to four years later, and the country witnesses the arrival of a new kind of opioid, marketed as being less addictive than the older opioids. Pills like OxyContin <img class='image block-icon' src='../../assets/images/background/background-op-icon.svg' alt=''/>  hit the shelves after being approved by the FDA, who stated the new formula would result in less abuse potential because the drug would be absorbed slowly, with no immediate “rush.”",
      },

      {
        type: "scrollable-image-row",
        style: "",
        images: [
          {
            caption:
              "Bottles of Oxycontin in a pharmacy, the most prescribed pills of the late 90s and early 2000s. (Ewing in Portland Press Herald, 2000.)",
            image: "../../assets/images/background/background-image-4.jpg",
          },
          {
            caption:
              "Charlie sits in his house on the corner of Kensington Ave and Tusculum Str. (Stockbridge, 2012)",
            image: "../../assets/images/background/background-image-5.jpg",
          },
        ],
      },

      {
        type: "image-text-block",
        style: "default",
        label: "P02.04",
        text: "OxyContin <img class='image block-icon' src='../../assets/images/background/background-op-icon.svg' alt=''/> was suddenly prescribed for long periods of time, even when there was no clear medical need. In just three years, the number of prescriptions for the drug quadrupled across the country and thousands of people became dependent on it, as their tolerance grew, and their supply couldn't keep up.",
        image:
          "../../assets/images/background/background-image-text-block-1.jpg",
        caption:
          "Kevin (left) started with Percocet before moving on to Oxycontin, then heroin. (Stockbridge, 2010)",
      },
      {
        type: "image-text-block",
        style: "reverse",
        label: "P02.05",
        text: "So, they came up with new and dangerous ways to get the most out of each pill: crushing, smoking, snorting, or injecting them intravenously. But the worst was yet to come. In 1998, a new pain medicine was approved to treat cancer with the name of Fentanyl <img class='image block-icon' src='../../assets/images/background/c-icon.svg' alt=''/>",
        image:
          "../../assets/images/background/background-image-text-block-2.jpg",
        caption:
          "Debbie sits on Kensington Ave., Philadelphia, one of the worst affected areas by the opioid crisis. (Stockbridge, 2009)",
      },

      {
        type: "p",
        style: "indent-block-5",
        label: "P02.06",
        text: "Following this sudden rise in 2003, the FDA issued a warning letter to OxyContin’s manufacturer, Purdue Pharma, for misleading advertisements. Later, in 2009, it began working with the US Drug Enforcement Administration (<img class='image block-icon union' src='../../assets/images/background/union-icon.svg' alt=''/>) to help educate the public on the safe disposal of opioids.",
      },

      {
        type: "image-caption",
        style: "",
        image: "../../assets/images/background/background-image-full-1.jpg",
        caption:
          "Debbie writes this journal entry as she sits on Kensington Avenue. (Stockbridge, 2009)",
      },
      {
        type: "full-image",
        style: "full-image",
        image: "../../assets/images/background/background-image-full-2.jpg",
        imageMobile:
          "../../assets/images/background/background-image-full-2-mobile.jpg",
        caption:
          "Jamie relapsed after being prescribed Xanax, leading her back to opioids. (Stockbridge, 2011)",
      },
      {
        type: "wave",
        style: "wave-2",
        title1: "The Second Wave:",
        title2: "HEROIN",
      },
      {
        type: "p",
        style: "indent-block-4",
        label: "P02.07",
        text: "As years passed, the number of people misusing prescription opioids began to increase. For those who had developed a dependency and tolerance to prescription opioids, they soon turned to heroin – a faster and cheaper option. This period came to be known as “The Heroin Epidemic”.",
      },
      {
        type: "scrollable-image-row",
        style: "",
        images: [
          {
            caption:
              "Debbie injects heroin in an abandoned house in Philadelphia. (Stockbridge, 2009)",
            image: "../../assets/images/background/background-image-6.jpg",
          },
          {
            caption:
              "Crystal gets high in a house with no heat, electricity or running water. (Stockbridge, n.d.)",
            image: "../../assets/images/background/background-image-7.jpg",
          },
        ],
      },

      {
        type: "image-text-block",
        style: "default",
        label: "P02.08",
        text: "During this time, many drug dealers began lacing their heroin with Fentanyl <img class='image block-icon' src='../../assets/images/background/c-icon.svg' alt=''/> a synthetic opioid up to 50 times stronger and more addictive than heroin, making it more attractive for dealers looking to make a profit.",
        image:
          "../../assets/images/background/background-image-text-block-3.jpg",
        caption:
          "The scars on Melissa’s arms are the result of prolonged heroin use. (Stockbridge, 2011)",
      },
      {
        type: "image-text-block",
        style: "reverse",
        label: "P02.08",
        text: "The FDA attempted to respond by updating their regulations. This led pharmaceutical companies to either remove pills like Vicodin from the market or slightly alter their formula to comply.",
        image:
          "../../assets/images/background/background-image-text-block-4.jpg",
        caption:
          "“You never think it’s gonna happen to you until you come down and, and actually see it for yourself” Jessica. (Stockbridge, 2010)",
      },

      {
        type: "p",
        style: "indent-block-5",
        label: "P02.09",
        text: "This sudden disruption caused doctors to cut off millions of patients from their prescriptions and forced them to seek alternatives—primarily in the streets. Despite the good intentions of governmental organizations to issue warning labels, establish projects, and set guidelines, the epidemic was already underway.",
      },
      {
        type: "full-image",
        style: "full-image",
        image: "../../assets/images/background/background-image-full-3.jpg",
        imageMobile:
          "../../assets/images/background/background-image-full-3-mobile.jpg",
        caption:
          "Edward writes a journal entry in a known heroin hotspot. He died of overdose soon after. (Stockbridge, 2011)",
      },
      {
        type: "wave",
        style: "wave-3",
        title1: "The Third Wave:",
        title2: "SYNTHETIC OPIOIDS",
      },
      {
        type: "p",
        style: "indent-block-4",
        label: "P02.10",
        text: "Heroin was still looming through the streets, but the real problem became the surge of synthetic opioids. Lab-made opioids fentanyl and carfentanil are 100 and 10000 times stronger than morphine respectively, and just a milligram can be lethal. The United States saw its sharpest rise in drug-related deaths in 2016 with over 20,000 deaths from fentanyl and related drugs.",
      },

      {
        type: "scrollable-image-row",
        style: "",
        images: [
          {
            caption:
              "Robert with a photo of his brother Edward, who had recently OD’d. (Stockbridge, 2011)",
            image: "../../assets/images/background/background-image-8.jpg",
          },
          {
            caption:
              "Robert replies to his recently deceased brother Edward, one month later. (Stockbridge, 2011)",
            image: "../../assets/images/background/background-image-9.jpg",
          },
        ],
      },

      {
        type: "image-text-block",
        style: "default",
        label: "P02.11",
        text: "It was known as the deadliest time of the opioid epidemic until a new pandemic struck: COVID-19. In 2020 the United States saw an unprecedented increase in overdoses and deaths, especially among minority communities.",
        image:
          "../../assets/images/background/background-image-text-block-5.jpg",
        caption:
          "Police officers question two women on Gurney Street in Kensington, Philadelphia. (Stockbridge, 2017)",
      },
      {
        type: "image-text-block",
        style: "reverse",
        label: "P02.12",
        text: "Besides demanding more warning labels and legal notices in pharmaceutical marketing, the FDA also approved naloxone injections and Narcan nasal spray.",
        image:
          "../../assets/images/background/background-image-text-block-6.jpg",
        caption:
          "A man overdoses in Philadelphia, but bystanders administer Narcan in time. (Stockbridge, 2017)",
      },

      {
        type: "p",
        style: "indent-block-5",
        label: "P02.13",
        text: "Both medications can temporarily stop or reverse the effects of an opioid overdose which make them one of the most—if not the most—useful tools for health-care professionals up to this point. Simultaneously, the DEA doubled down on its “War on Drugs” and the government invested in prevention and education programs while also encouraging more effective treatments for opioid use disorder.",
      },
      {
        type: "full-image",
        style: "full-image",
        image: "../../assets/images/background/background-image-full-4.jpg",
        imageMobile:
          "../../assets/images/background/background-image-full-4-mobile.jpg",
        caption:
          "Friends and family create a memorial for Nicole in Philadelphia. (Stockbridge, 2013)",
      },

      {
        type: "title",
        style: "titles",
        titles: [
          { text: "THE WAY", style: "left  " },
          { text: "forward:", style: "left indent-1 " },
          { text: "harm", style: "left indent-2 " },
          { text: "reduction", style: "left indent-3 " },
        ],
      },
      {
        type: "p",
        style: "indent-block-4",
        label: "P03.01",
        text: "The response to the opioid epidemic must be comprehensive and address all aspects of the problem, from prevention and treatment to law enforcement and education. There is a long way to go on many fronts, starting with the fact that only one in ten people who need treatment for addiction ever receive it, only one in four physicians and nurses have received addiction training, and 60 percent of residential treatment facilities do not offer the most effective treatment for addiction: medication.",
      },
      {
        type: "scrollable-image-row",
        style: "",
        images: [
          {
            caption:
              "First Stop Recovery House in the heart of Kensington, Philadelphia. (Stockbridge, 2017)",
            image: "../../assets/images/background/background-image-10.jpg",
          },
          {
            caption:
              "Frank waits for a group session to start at the First Stop Recovery House. (Stockbridge, 2017)",
            image: "../../assets/images/background/background-image-11.jpg",
          },
        ],
      },

      {
        type: "image-text-block",
        style: "default",
        label: "P03.02",
        text: "Over the years, the government has attempted to respond—first, by renewing the War on Drugs, which only worsened the issue by punishing rather than helping. Later on, during the Obama administration, there was a push to increase access to medication, giving health-care professionals their most useful tool.",
        image:
          "../../assets/images/background/background-image-text-block-7.jpg",
        caption:
          "Matt, 3 years in recovery. He first met the photographer in 2011 when he was in active addiction. (Stockbridge, 2017)",
      },
      {
        type: "image-text-block",
        style: "reverse",
        label: "P03.03",
        text: "In 2017, Trump officially declared it a national health issue and promised additional funds for treatments, medication, and services. Unfortunately, the funds never came. Now, the Biden administration has introduced new approaches, among which the Harm Reduction treatment stands out. It offers a spectrum of strategies that meet people where they are, on their own terms, and acknowledges that in terms of treatments for addiction, there is no “one size fits all.”",
        image:
          "../../assets/images/background/background-image-text-block-8.jpg",
        caption:
          "Nichole takes a moment to appreciate the sun on Kensington Ave, Philadelphia. (Stockbridge, n.d.)",
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "the 8 principles", style: "left" },
          { text: "of harm", style: "left indent-1 " },
          { text: "reduction", style: "left indent-3 " },
        ],
      },

      {
        type: "scroll-table",
        style: "scroll-table",
        cols: [
          {
            title: "ACKNOWLEDGE REALITY",
            text: "Drug use is part of our world. Instead of condemning it, focus on minimising harm.",
            tags: [
              {
                text: "principle 01",
                color: "#E8C50B",
              },
            ],
          },
          {
            title: "UNDERSTAND COMPLEXITY",
            text: "Drug use is a vast spectrum, but some practices are safer than others.",
            tags: [
              {
                text: "principle 02",
                color: "#EC831A",
              },
            ],
          },
          {
            title: "PRIORITIZE WELL-BEING",
            text: "Drug use interventions are most successful when individual and community wellbeing are prioritized.",
            tags: [
              {
                text: "principle 03",
                color: "#A5115C",
              },
            ],
          },
          {
            title: "PROVIDE COMPASSION",
            text: "Non-judgemental, non-coercive services and resources are key to supporting those in need.",
            tags: [
              {
                text: "principle 04",
                color: "#2990F1",
              },
            ],
          },
          {
            title: "PROMOTE INCLUSIVITY",
            text: "Drug users should be involved in policy making and creating programs for their benefit.",
            tags: [
              {
                text: "principle 05",
                color: "#5C9E07",
              },
            ],
          },
          {
            title: "EMPOWER USERS",
            text: "Drug users should lead harm reduction efforts by sharing information and supporting each other.",
            tags: [
              {
                text: "principle 06",
                color: "#DE9DB3",
              },
            ],
          },
          {
            title: "ADDRESS SOCIAL CONTEXT",
            text: "Social inequalities have an impact on drug-related harm, meaning some communities are more vulnerable.",
            tags: [
              {
                text: "principle 07",
                color: "#CF1408",
              },
            ],
          },
          {
            title: "FACE RISKS REALISTICALLY",
            text: "With drug use comes real harm and danger; it should not be minimised or ignored.",
            tags: [
              {
                text: "principle 08",
                color: "#CCB599",
              },
            ],
          },
        ],
      },
      {
        type: "p",
        style: "indent-block-5 top-1",
        label: "P03.04",
        text: "These strategies start by adjusting public health policies such as introducing safer opioids to be used as substitutes, training health-care professionals, and investing in long-term treatment plans that adjust to patients’ needs. The crucial issue concerns how to get people one step closer to beginning their treatment.",
      },
      {
        type: "p",
        style: "inset bottom-1",
        label: "P03.05",
        text: "Harsh consequences, shame, and punishment are simply not effective ways to heal a person’s addiction; there must be a different strategy, and it begins by using a different perception of addiction. It’s not a moral choice that people make. It doesn't mean they're criminals, and they shouldn't feel ostracized. Addiction, simply put, is a disease.",
      },
      {
        type: "image-caption",
        style: "",
        image: "../../assets/images/background/background-image-bottom-1.jpg",
        caption:
          "Debbie with her mother. She was in recovery for 7 years but sadly OD’d a year later. (Stockbridge, 2017)",
      },

      {
        type: "p",
        style: "indent-block-4",
        label: "P03.06",
        text: "Unfortunately, addiction carries significant stigma, which prevents people from seeking treatment. This is where the power of human connection, empathy, and understanding comes in. When people are at their most vulnerable state, they can either fall back to their substance use disorder or they can find hope in other people’s stories of recovery. And once they willingly take that first step, it’s easier to help them find the type of treatment that best suits their needs.",
        buttons: [
          {
            text: "HEAR HOW OTHERS DID IT",
            link: "../../stories",
            style: "orange",
          },
        ],
      },
    ],
  },

  quit: {
    meta: {
      slug: "quit",
      title: "Quit",
      description:
        "The helpline that turns the object of addiction into a way out.",
    },
    title:
      "want<br />t<img class='title-icon' src='../../assets/images/quit/quit-title-icon.svg' /> quit?",
    subTitle: "",
    blocks: [
      {
        type: "p",
        style: "indent-block",
        text: "Quitting is no easy task, but one thing's for sure – it does not matter how slowly you go, as long as you do not stop. Recent research has improved your treatment options, and there will always be someone there to support you.",
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "starting", style: "left indent-3" },
          { text: "a treatment", style: "left" },
        ],
      },
      {
        type: "p",
        style: "inset",
        label: "P01",
        text: "There are several options, it’s just about finding the best one for you. Start by considering what you prefer for your recovery:",
      },
      {
        type: "image-row",
        style: "two-col",
        cols: [
          {
            imageCaption:
              "Frank at the First Stop Recovery House on Kensington Ave. (Stockbridge,&nbsp;2017)",
            image: "../../assets/images/quit/quit-row-1-1.jpg",
            caption: "plan A",
            title: "FACILITY",
            text: "Living full time at a facility while receiving care and treatment.",
          },
          {
            imageCaption:
              "Debbie and her mother Laura in their home in Staunton, Virginia. (Stockbridge,&nbsp;2017)",
            image: "../../assets/images/quit/quit-row-1-2.jpg",
            caption: "plan B",
            title: "HOME",
            text: "Staying at home and going to a facility a few hours a week.",
          },
        ],
      },

      {
        type: "p",
        style: "indent-block",
        label: "P02",
        text: "Talk to a doctor or therapist who’s trained in addiction treatments. They’ll assess your symptoms and suggest the best treatments and services, taking into consideration your unique needs.",
      },
      {
        type: "p",
        style: "inset",
        label: "P03",
        text: "You can find more information on SAMHSA’s nationwide treatment locator.",
        buttons: [
          {
            text: "findtreatment.gov",
            link: "https://findtreatment.gov/",
            style: "outline",
          },
        ],
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "choosing", style: "left indent-3" },
          { text: "a therapy", style: "left" },
        ],
      },
      {
        type: "scroll-table",
        style: "scroll-table",
        cols: [
          {
            title: "WITHDRAWAL MANAGEMENT",
            text: "A doctor can help you ease the withdrawal symptoms by using medication in a controlled and safe setting.",
            tags: [
              {
                text: "OPTION 01",
                color: "#E8C50B",
              },
            ],
          },
          {
            title: "COGNITIVE BEHAVIORAL THERAPIES",
            text: "These can help you recognize and accept there’s a substance use disorder. They help to maintain your motivation so you can manage stress and anxiety, avoid triggering a relapse, and stay the course with your treatment.",
            tags: [
              {
                text: "OPTION 02",
                color: "#EC831A",
              },
            ],
          },
          {
            title: "INDIVIDUAL COUNSELING",
            text: "Private one-on-one counseling to explore deep personal problems.",
            tags: [
              {
                text: "OPTION 03",
                color: "#A5115C",
              },
            ],
          },
          {
            title: "GROUP COUNSELING",
            text: "It’s doesn’t have to feel like you against the world. Get support in a group discussion of six to ten people and discuss the struggles, experiences, and problems of the recovery process.",
            tags: [
              {
                text: "OPTION 04",
                color: "#2990F1",
              },
            ],
          },
          {
            title: "FAMILY COUNSELING",
            text: "You’re all in this together; if it affects one, it affects everyone. Learn how to heal from the trauma of addiction, and guide one another through positive changes.",
            tags: [
              {
                text: "OPTION 05",
                color: "#5C9E07",
              },
            ],
          },
          {
            title: "EDUCATION",
            text: "The more you learn, the better prepared you are during recovery. You will also be offered education about addiction, how it works, why some are more susceptible, and the stages of a relapse.",
            tags: [
              {
                text: "OPTION 06",
                color: "#DE9DB3",
              },
            ],
          },
          {
            title: "MEDICATION",
            text: "Medication can complement a larger treatment plan. It can help your brain become less dependent to opioid use, which will relieve cravings and withdrawal symptoms. Seek a doctor’s counsel to understand its usage.",
            tags: [
              {
                text: "OPTION 07",
                color: "#CF1408",
              },
            ],
          },
        ],
      },

      {
        type: "title",
        style: "titles",
        titles: [
          { text: "symptoms&nbsp;&nbsp;&nbsp;&nbsp;of", style: "right" },
          { text: "withdrawal", style: "right" },
        ],
      },
      {
        type: "outline-title",
        style: "outline-titles",
        subTitle: "early symptoms",
        titles: [
          { text: "WITHIN", style: "left" },
          { text: "12-30 HOURS", style: "left" },
          { text: "SINCE LAST DOSE", style: "left" },
        ],
      },

      {
        type: "stepped-block",
        style: "stepped-block",
        blocks: [
          { number: "01", text: "AGITATION", color: "#CCB599" },
          { number: "02", text: "RUNNY NOSE", color: "#DE9DB3" },
          { number: "03", text: "MUSCLE ACHES", color: "#E8C50B" },
          { number: "04", text: "INCREASED TEARING", color: "#F8401C" },
          { number: "05", text: "INSOMNIA", color: "#2990F1" },
          { number: "06", text: "ANXIETY", color: "#5C9E07" },
          { number: "07", text: "SWEATING", color: "#A5115C" },
          { number: "08", text: "YAWNING", color: "#CF1408" },
        ],
      },

      {
        type: "outline-title",
        style: "outline-titles",
        subTitle: "later symptoms",
        titles: [
          { text: "AFTER", style: "left" },
          { text: "30 HOURS", style: "left" },
        ],
      },

      {
        type: "stepped-block",
        style: "stepped-block",
        blocks: [
          { number: "01", text: "GOOSE BUMPS", color: "#CCB599" },
          { number: "02", text: "DILATED PUPILS", color: "#DE9DB3" },
          { number: "03", text: "ABDOMINAL CRAMPING", color: "#E8C50B" },
          { number: "04", text: "DIARRHEA", color: "#EC831A" },
          { number: "05", text: "NAUSEA", color: "#2990F1" },
          { number: "06", text: "VOMITING", color: "#5C9E07" },
        ],
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "after", style: "left indent-3" },
          { text: "treatment", style: "left" },
        ],
      },
      {
        type: "p",
        style: "indent-block",
        label: "P05",
        text: "It's important to keep treatment going as a lifelong support, for you and for others. Belonging to recovery groups will help people build on what they learned in treatment as they navigate life in recovery.",
      },
      {
        type: "p",
        style: "inset",
        label: "P06",
        text: "Peer support has been proven to be one of the most effective ways to maintain long-term recovery. These groups provide a sense of community where people can share experiences and find comfort. Whether you choose online or in-person groups, know you’re not alone on this journey.",
        buttons: [
          {
            text: "HEAR HOW OTHERS DID IT",
            link: "../../stories",
            style: "orange",
          },
        ],
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "additional", style: "left" },
          { text: "resources", style: "right" },
        ],
      },

      {
        type: "info-table",
        style: "info-table",
        title: "IF YOU WANT TO QUIT",
        headings: [
          { text: "INDEX" },
          { text: "visual" },
          { text: "organization" },
          { text: "website" },
        ],
        rows: [
          {
            index: "01",
            icon: "../../assets/images/quit/treat-icon.png",
            text: "Findtreatment.gov",
            linkText: "Findtreatment.gov",
            link: "https://findtreatment.gov/",
          },
          {
            index: "02",
            icon: "../../assets/images/quit/plant-icon.png",
            text: "In the Rooms Treatment Program Finder",
            linkText: "intherooms.com",
            link: "https://www.intherooms.com/home/",
          },
          {
            index: "03",
            icon: "../../assets/images/quit/oxford-icon.png",
            text: "Oxford House Vacancies",
            linkText: "oxfordvacancies.com",
            link: "https://www.oxfordvacancies.com",
          },
          {
            index: "04",
            icon: "../../assets/images/quit/smart-icon.png",
            text: "SMART Recovery",
            linkText: "smartrecovery.org/",
            link: "https://smartrecovery.org/",
          },
          {
            index: "05",
            icon: "../../assets/images/quit/samhsa-icon.png",
            text: "Substance Abuse and Mental Health Services Administration (SAMHSA)",
            linkText: "samhsa.gov/",
            link: "https://www.samhsa.gov/",
          },
        ],
      },
      {
        type: "info-table",
        style: "info-table",
        title: "IF SOMEONE YOU LOVE WANTS TO QUIT",
        headings: [
          { text: "INDEX" },
          { text: "visual" },
          { text: "organization" },
          { text: "website" },
        ],
        rows: [
          {
            index: "01",
            icon: "../../assets/images/quit/nar-anon-icon.png",
            text: "Nar-Anon Family Groups",
            linkText: "nar-anon.org",
            link: "https://www.nar-anon.org/",
          },
          {
            index: "02",
            icon: "../../assets/images/quit/partner-icon.png",
            text: "Partnership to End Addiction",
            linkText: "drugfree.org",
            link: "https://drugfree.org/",
          },
          {
            index: "03",
            icon: "../../assets/images/quit/seasame-icon.png",
            text: "Sesame Street Workshop",
            linkText: "sesameworkshop.org",
            link: "https://sesameworkshop.org/",
          },
          {
            index: "04",
            icon: "../../assets/images/quit/betty-icon.png",
            text: "The Hazelden Betty Ford Foundation",
            linkText: "hazeldenbettyford.org",
            link: "https://www.hazeldenbettyford.org/",
          },
          {
            index: "05",
            icon: "../../assets/images/quit/samhsa-icon.png",
            text: "Substance Abuse and Mental Health Services Administration (SAMHSA)",
            linkText: "samhsa.gov/",
            link: "https://www.samhsa.gov/",
          },
        ],
      },
    ],
  },

  act: {
    meta: {
      slug: "act",
      title: "Act",
      description:
        "The helpline that turns the object of addiction into a way out.",
    },
    title:
      "ab<img class='title-icon' src='../../assets/images/act/act-title-icon.svg' />ut the<br/>initia&ZeroWidthSpace;tive",
    subTitle: "",
    blocks: [
      {
        type: "p",
        style: "indent-block",
        text: "855-HOW-TO-QUIT is an initiative to fight the opioid epidemic, by turning the the object of addiction into a way out. Through a helpline that uses the imprint codes of the most common opioid pills as phone extensions, connecting those who struggle with addiction to those who managed to quit. The initiative is developed by a group of NGOs, healthcare providers, communication experts, artists, and activists, and open to anyone who wishes to join the fight.",
      },
      // {
      //   type: "title",
      //   style: "titles",
      //   titles: [
      //     { text: "Faces and", style: "left indent-1" },
      //     { text: "Voices&nbsp;&nbsp;&nbsp;of", style: "left" },
      //     { text: "Recovery", style: "right" },
      //   ],
      // },
      // {
      //   type: "p",
      //   style: "indent-block",
      //   text: "A national nonprofit organization dedicated to promoting long-term recovery from addiction to alcohol and drugs. They seek to reduce stigma and improve access to treatment by harnessing the power of personal stories of recovery. Stories of individuals, of families, and communities. Stories to inspire others who are looking for a way to quit.",
      // },
      // {
      //   type: "p",
      //   style: "inset",
      //   text: "They support recovery nationwide by advocating for more inclusive and science-based policies, increased access to care and services, and ensuring that people with lived experience are at the forefront of every decision.",
      // },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "Get", style: "left indent-2" },
          { text: "Involved", style: "left indent-1" },
        ],
      },

      {
        type: "table",
        style: "four-col",
        cols: [
          {
            title: "Engage",
            text: "Stay active and engaged in pushing for reforms, backing legislation, and influencing decisions that align with the mission of advancing recovery.",
            buttons: [
              {
                text: "ADVOCATE",
                link: "https://facesandvoicesofrecovery.org/advocate/take-action/",
              },
            ],
          },
          {
            title: "PARTICIPATE",
            text: "Every helping hand, face, and voice, can mean the world to somebody in need. You will grow personally performing various tasks in the organization, while having a positive impact on others.",
            buttons: [
              {
                text: "VOLUNTEER",
                link: "https://www.shatterproof.org/volunteer",
              },
            ],
          },
          {
            title: "CONTRIBUTE",
            text: "Any contribution enables the organization to scale-up the efforts and to ensure that every message reaches as many people as possible.",
            buttons: [
              {
                text: "DONATE",
                // link: "https://www.wearethosepeople.org/donations",
                link: "https://facesandvoicesofrecovery.org/engage/donate/",
              },
            ],
          },
          {
            title: "SUPPORT",
            text: "If you have overcome addiction, we’d love to hear about your journey to recovery. It helps to build a society where recovery is acknowledged and celebrated.",
            buttons: [
              {
                text: "SHARE YOUR STORY",
                link: "https://facesandvoicesofrecovery.org/advocate/stories/submit/",
              },
            ],
          },
        ],
      },
      {
        type: "title",
        style: "titles",
        titles: [
          { text: "Project", style: "left indent-1" },
          { text: "Contributors", style: "left" },
        ],
      },
      {
        type: "table",
        style: "two-col",
        cols: [
          {
            title: "Anzen Health",
            text: "Anzen Health, a U.S.-based healthcare consultancy, champions equitable access to critical healthcare tools and services. Leveraging their expertise in optimizing supply chains and integrating emerging technologies, they deliver innovative solutions for a diverse clientele. Notably, their client portfolio includes partnerships with leading rehabilitation facilities, demonstrating their commitment to building a stronger healthcare system.",
            buttons: [
              {
                text: "WEBSITE",
                link: "https://www.anzenhealth.com",
              },
            ],
          },
          // {
          //   title: "WE ARE THOSE PEOPLE",
          //   text: "A 501(c)(3) non-profit organization dedicated to eliminating the stigma associated with substance use disorder, advocating for Mental Health Care Reform, and educating the community that recovery is possible. They share in-depth perspectives through the power of storytelling, community building, and advocating for change in the mental healthcare system.<br /><br />They support recovery through innovative reform policies, and by collaborating with artists and creatives to promote the cause.",
          //   buttons: [
          //     {
          //       text: "WEBSITE",
          //       link: "https://www.wearethosepeople.org/",
          //     },
          //   ],
          // },
          {
            title: "JEFFREY STOCKBRIDGE",
            text: "Philadelphia-based photographer who extensively covered the opioid epidemic in his city for over 10 years.<br /><br /> Kensington Blues is his portrait photography project and book documenting the lives of the people struggling to survive their addiction along the infamous Kensington Avenue.<br /><br />Working with a 4x5 film camera, journal and audio recorder, Stockbridge shares these resident's stories in order to challenge the negative stereotypes around addiction.",
            buttons: [
              {
                text: "ABOUT THE PROJECT",
                link: "https://kensingtonblues.com",
              },
              {
                text: "BUY BOOK",
                link: "https://jeffreystockbridge.com/?page_id=1721",
              },
            ],
          },
        ],
      },
      {
        type: "table",
        style: "two-col",
        cols: [
          {
            title: "Oliver Würffell",
            text: "Oliver Würffell is a New York-based filmmaker. As an expert storyteller, he has worked on a wide range of films, both short- and long-form, with his latest short featured in Galerie Deschler, Berlin. Through the 855-HOW-TO-QUIT-(OPIOIDS) PSA film, he captures the struggles of addiction along with the renewal of hope for recovery.",
            buttons: [
              {
                text: "WEBSITE",
                link: "http://www.wuerffell.com",
              },
            ],
          },
          {
            title: "PAIN",
            text: "A a 501(c)(3) non-profit organization specializing in rehabilitation services, emotional support, and community outreach for those affected by substance use disorder. Their program focuses on family, the effective coordination of personalized rehabilitation for those with substance use disorder, Narcan (naloxone) training and dispensing, building an extensive network of community partnerships, and the operation of a Sober Living Home for people newly in recovery.",
            buttons: [
              {
                text: "WEBSITE",
                link: "https://painnonprofit.org",
              },
            ],
          },
        ],
      },

      {
        type: "table",
        style: "two-col",
        cols: [
          {
            title: "Upstate Opioid Bridge Clinic",
            text: "The Upstate Opioid Bridge Clinic employs an innovative approach to combat opioid use disorder by providing comprehensive treatment, including an initial dose of buprenorphine. The clinic prioritizes short-term outpatient care, aiming to destigmatize opioid use disorder while connecting patients to community resources for holistic support in areas such as food, housing, and transportation. In addition to opioid use disorder, the Bridge Clinic also sees patients suffering from any substance abuse, including alcohol, cocaine, and others.",
            buttons: [
              {
                text: "WEBSITE",
                link: "https://www.upstate.edu/emergency/healthcare/bridge-clinic.php",
              },
            ],
          },
          {
            title: "WESOBERNOW",
            text: "An online and on-the-street organization dedicated to raising awareness about fentanyl. They offer individuals struggling with addiction a platform for connection, valuable resources, and a healthy escape through entertainment. Through TikTok and a top-rated podcast, WESOBERNOW has made a significant impact in the online space. They also provide free speaker sessions, live music performances for sober living communities, and hosting community events both locally in the valley and across the country. Now including a new emphasis in supporting people in Spanish.",
            buttons: [
              {
                text: "WEBSITE",
                link: " https://www.wesobernow.net",
              },
            ],
          },
        ],
      },
      {
        type: "table",
        style: "two-col single",
        cols: [
          {
            title: "OXFORD HOUSES",
            text: "Oxford Houses are self-run, democratically operated recovery homes in the United States for individuals overcoming substance use disorder. Their mission revolves around providing a supportive environment where residents can achieve sobriety, rebuild their lives, and reintegrate into society. With over 3,700 houses nationwide, Oxford Houses have demonstrated significant success in promoting long-term recovery, fostering accountability, and reducing recidivism rates among individuals battling addiction.",
            buttons: [
              {
                text: "WEBSITE",
                link: "https://www.oxfordvacancies.com",
              },
            ],
          },
        ],
      },
    ],
  },

  faq: {
    meta: {
      slug: "faq",
      title: "Frequently Asked Questions",
      description:
        "The helpline that turns the object of addiction into a way out.",
    },
    title:
      "frequently asked questi<img class='title-icon' src='../../assets/images/faq/faq-title-icon.svg' />ns",
    mobileTitle:
      "frequ<br />ently<br />asked<br />ques<br />ti<img class='title-icon' src='../../assets/images/faq/faq-title-icon.svg' />ns",
    subTitle:
      "Frequently asked questions about the purpose of the website, struggles of addiction, data collection etc.",

    faqs: [
      {
        question: "ARE THESE STORIES REAL?",
        answer:
          "Yes. These stories are based on true events. For the sake of protecting the anonymity of the people, some of them were interpreted by voice actors. To guarantee professional support, the stories were curated by trained addiction specialists to include important medical information.",
      },
      {
        question: "WILL I BE SPEAKING TO A REAL PERSON?",
        answer:
          "While the helpline is available 24/7, due to the concept of providing individual care based on different pills, a fully live-operated helpline is beyond the capacities of this initiative. Still, the helpline is operated live at times, depending on the availability of the people sharing their stories, as well as for the launch and during special events in the future. At all other times, the helpline provides recorded versions of each of the stories.",
      },
      {
        question: "What are the hours of operation?",
        answer:
          "The helpline is available 24 hours a day, 7 days a week. However, if you are experiencing a medical emergency or any type of life-threatening situation, please dial 911.",
      },

      {
        question: "Will my information be kept private and confidential?",
        answer:
          "The helpline is strictly informational and anonymous. Therefore, no data will be recorded or stored about callers or visitors to this website. Please have a look at our privacy policy for more detailed information.",
      },
      {
        question: "Is my call free?",
        answer:
          "Yes, all calls from the United States are 100% toll-free, however standard message and data rates may apply. Calls from abroad will be subject to international message and data rates; check with your provider for details.",
      },

      {
        question:
          "Could this content be considered professional medical advice?",
        answer:
          "No. While sourced from reputable sources, all content on our website or helpline is not professional medical advice. Please consult a medical professional for any health-related concerns. If you are experiencing a medical emergency, call 911.",
      },

      {
        question: "Who is this helpline for?",
        answer:
          "This helpline is intended for anyone who struggles with prescription opioid use disorder or knows someone who does. No matter who you are, if you feel you are or are becoming dependent on these pills, pick up the phone and dial the code on the pill to hear from someone who managed to quit that very same pill. Studies show that peer support is one of the most effective tools for recovery. Shared understanding, respect, and mutual empowerment only peers can provide have been proven to keep people more engaged in the recovery process.",
      },

      {
        question: "What do I do if I’m in a crisis situation?",
        answer:
          "You are not alone. Reach out to the following relevant free support hotlines for immediate help.<br /><br />For medical emergencies such as an overdose, please dial 911.<br /><br />For mental health emergencies and to talk to a crisis counselor, please call or text 988 for the Suicide and Crisis Hotline or chat at <a href='https://988lifeline.org'>988lifeline.org</a>.",
      },
      {
        question: "Will this helpline recommend me a treatment?",
        answer:
          "While you could consider other people’s experiences of recovery as inspiration for what to do, we still recommend consulting a professional to find the best treatment plan for you. That’s why you can dial 2 to be forwarded to the SAMHSA National Helpline: a federally funded helpline with excellent resources such as information services about opioids and treatment referrals based on your location. Alternatively, you can also contact them directly via the following methods:<br /><br />For their Treatment Referral Routing Service, you can either call 1-800-662-HELP (4357), consult their online treatment locator <a href='https://findtreatment.gov'>here</a>, or text your zip code to 435748 (HELP4U).<br /><br />For their confidential, free, 24/7 information service in both English and Spanish, call 1-800-487-4889.",
      },

      {
        question:
          "Why don’t I just call the SAMHSA National Helpline directly?",
        answer:
          "Our helpline is intended to empower people to ask for help in the first place. If you are ready to take that step, we urge you to call 1-800-662-HELP (4357) or text your zip code to 435748 immediately to get a treatment referral; they are available 24/7.",
      },
      {
        question:
          "Won’t redirecting these calls overburden the national helplines?",
        answer:
          "Over 3 million people in the U.S. struggle with opioid dependence, but due to stigma and other personal reasons, many people don’t ask for the help they need. We’re only trying to inspire people to take that first step through the proven method of sharing stories of recovery. If they choose to be redirected, we presume the increase in calls will help create a more accurate scope of the resources needed and thus potentially save lives.",
      },
      {
        question:
          "Why are there only prescription pills on this helpline; aren’t heroin and fentanyl the real killers?",
        answer:
          "While it’s true that the vast majority of overdoses in the past decades can be attributed to heroin and fentanyl – one of the more potent synthetic opioids – the vast majority of people are first introduced to opioids in the form of prescription pills directly from a doctor. Evidence over the past three decades shows that they pose a significant danger in and of themselves. This includes counterfeit prescription pills that are sometimes indistinguishable from the real ones.",
      },
      {
        question: "Is this helpline just trying to sell me a treatment?",
        answer:
          "This helpline is not for profit. The intention of the helpline is to inspire people to seek help. However, if callers choose to be forwarded to SAMHSA’s treatment referral routing service, it is possible that their recommended treatment plan is not covered by insurance and may be from a for-profit organization.",
      },
      {
        question: "How can I support this or get involved?",
        answer:
          "You can promote this cause by sharing this helpline with friends and family or on social media, as you never know who around you might need it. You can additionally help by supporting our partners. You can find more information about them on the <a href='../../act'>ABOUT OUR INITIATIVE</a> page.",
      },
      {
        question: "What should I do if the number is not working?",
        answer:
          "First, try to restart your phone or turn the airplane mode on and off. It may help your phone to have a better signal. Second, make sure you have the correct number, 855-469-86-7848, followed by the opioid pill imprint code. If that doesn’t work, try it without the extension. Third, make sure you have a US number, if you’re not in the US, add +1 before the number. <br /><br />In rare cases, select mobile carriers may experience issues with the number. If you’re experiencing this problem, try calling +1 (855) 469 8678.<br /><br />If it’s still not working, please send us an email with your location, your mobile carrier, and operating system at <a href='mailto:contact@855-how-to-quit.org'>contact@855-how-to-quit.org</a>",
      },
    ],
  },

  privacy: {
    meta: {
      slug: "privacy",
      title: "Privacy Policy",
      description:
        "The helpline that turns the object of addiction into a way out.",
    },
    title: "Privacy Policy",
    mobileTitle: "Priv<br />acy Policy",
    date: "Last Updated: March 4, 2024",
    content: [
      {
        title: "Introduction.",
        text: "Welcome to 855-HOW-TO-QUIT, a not-for-profit helpline and accompanying website dedicated to providing resources and information about overcoming opioid substance abuse. We care about your safety and privacy, and so we would like to explain how our technology uses your data. This policy applies to all interactions with 855-HOW-TO-QUIT.",
      },
      {
        title: "Helpline Purpose.",
        text: "Please note that 855-HOW-TO-QUIT is intended for informational purposes only and is not intended to be a substitute for, or to be relied upon as, medical advice, diagnosis, or treatment nor is it intended for crisis situations. If you require immediate assistance during a crisis, please contact your local emergency services, 911, or 988 for the National Suicide & Crisis Lifeline.",
      },
      {
        title: "Confidentiality.",
        text: "At 855-HOW-TO-QUIT, we are committed to maintaining the confidentiality of any information shared during calls. We aim to not record any calls nor store any identifying user data.",
      },
      {
        title: "Consent and Permissions.",
        text: "We are committed to handling all information shared with 855-HOW-TO-QUIT with explicit consent from the caller or website visitors. No details are intended to be used or disclosed without their permission unless required to do so by law.",
      },
      {
        title: "Information We Collect.",
        text: "We do not intend to collect any personally identifiable information from visitors to our helpline or website unless explicitly provided by the user voluntarily. However, this website does use Google Analytics to analyze website traffic and improve the content. Google Analytics collects anonymous, aggregated data that does not personally identify individual users.<br /><br />Please review Google Analytics' privacy policy for more information on how they collect and process data: Google Analytics Privacy Policy. <br /><a href='https://policies.google.com/privacy' target'_blank' >https://policies.google.com/privacy</a>",
      },
      {
        title: "Cookies.",
        text: "We do not use cookies or any other tracking technologies on our website. Therefore, we do not store any information on your device.",
      },
      {
        title: "Legal Disclosure.",
        text: "We may access and/or disclose personally identifying information shared with us if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or (b) comply with a legal process served on us.",
      },
      {
        title: "Third-Party Website Links and Social Media Sites.",
        text: "Occasionally on our Site we offer links to third-party websites and social media sites. These third-party sites have separate and independent privacy policies, and these third-parties may be located in a jurisdiction with lesser data protection laws. We, therefore, have no control over the content and activities of these linked sites.",
      },
      {
        title: "Third-Party Service Providers.",
        text: "Our helpline is made possible through the use of third-party service providers. While we at 855-HOW-TO-QUIT do not intend to store or disclose any possibly personally identifying information from callers to the helpline or visitors of the website, these third-party service providers have separate and independent data storage policies and these third-parties may be located in a jurisdiction with lesser data protection laws. We, therefore, have no control over the content and activities of these service providers.",
      },
      {
        title: "Forwarding to Other Helplines.",
        text: "At any time on the helpline you may dial “1” to be forwarded to the 988 lifeline for crisis support or “2” for SAMHSA’s National Helpline. These helplines are not operated by us. Please be aware that we have no control over the content and practices of these helplines and cannot accept responsibility or liability for their respective privacy policies.",
      },
      {
        title: "International Users.",
        text: "Our Services are designed for and targeted to U.S. audiences and are governed by and operated in accordance with the laws of the U.S. We make no representation that our Services are operated in accordance with the laws or regulations of any other nations. By using our Services and providing us with information, you understand and agree that your information may be transferred to and stored on servers located outside your resident jurisdiction and, to the extent you are a resident of a country other than the United States, that you consent to the transfer of such data to the United States for processing by us in accordance with this Privacy Policy.",
      },
      {
        title: "Your California Privacy Rights.",
        text: "California residents are entitled once a year, free of charge, to request and obtain certain information regarding our disclosure, if any, of certain categories of personal information to third parties for their direct marketing purposes in the preceding calendar year. To review, correct and update your personal information to ensure it is accurate, please use the contact information below. We do not share personal information with third parties for their own direct marketing purposes.",
      },
      {
        title: "Your Consent.",
        text: "By using our helpline and website, you consent to the terms outlined in this privacy policy.",
      },
      {
        title: "Changes to this Privacy Policy.",
        text: "This policy will be regularly reviewed and updated to reflect changes in technology, laws, and organizational practices. We reserve the right to update or modify this privacy policy at any time. Changes will be effective immediately upon posting on this page.",
      },
      {
        title: "Contact Us.",
        text: "If you have any questions or concerns about this privacy policy, please contact us at:<br /><a href='mailto:contact@855-how-to-quit.org'>contact@855-how-to-quit.org</a>",
      },
    ],
  },

  imprint: {
    meta: {
      slug: "imprint",
      title: "Imprint",
      description:
        "The helpline that turns the object of addiction into a way out.",
    },
    title: "Imprint",
    mobileTitle: "Im<br />print",
    subTitle: "",
    content: {
      title: "Anzen Health",
      address:
        "4245 N. Knox Ave.<br />Chicago, IL 60641<br /><a href='mailto:info@anzenhealth.com'>info@anzenhealth.com</a>",
      phone: "Phone: +1 (312)-447-9471",
      email:
        "Email:<a href='mailto:info@anzenhealth.com'>info@anzenhealth.com</a>",
      website:
        "<a target='_blank' href='https://www.anzenhealth.com/'>https://www.anzenhealth.com/</a>",
    },
  },
};

export { site };
